/** == Fonts (_fonts.scss) == **/
// Font-face declarations for custom font loadings 

//SourceSansPro
@font-face {
	font-family: 'SourceSansPro';
	src:	local('☺'),
			url('../fonts/SourceSansPro-Regular-Cyr.woff2') format('woff2'),
			url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'SourceSansPro';
    src:    local('☺'),
            url('../fonts/SourceSansPro-Regular-Lat.woff2') format('woff2'),
            url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: 'SourceSansPro';
    src:    local('☺'),
            url('../fonts/SourceSansPro-SemiBold-Cyr.woff2') format('woff2'),
            url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'SourceSansPro';
    src:    local('☺'),
            url('../fonts/SourceSansPro-SemiBold-Lat.woff2') format('woff2'),
            url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

// Exo2
@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-Regular-Cyr.woff2') format('woff2'),
            url('../fonts/Exo2-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-Regular-Lat.woff2') format('woff2'),
            url('../fonts/Exo2-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-SemiBold-Cyr.woff2') format('woff2'),
            url('../fonts/Exo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-SemiBold-Lat.woff2') format('woff2'),
            url('../fonts/Exo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-ExtraBold-Cyr.woff2') format('woff2'),
            url('../fonts/Exo2-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Exo2';
    src:    local('☺'),
            url('../fonts/Exo2-ExtraBold-Lat.woff2') format('woff2'),
            url('../fonts/Exo2-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

