/** == Posts listings (_builin.scss) == */
// Styles for posts listings and cards with CPT varianst

/** Commons **/
.thumbnail-link { 
    display: block;
}

.sep { 
    padding: 0 4px; 
}

.fixed-top {
	position: fixed;
	top: 99px + 90px;
}

.fixed-bottom {
	position: absolute;
	bottom: 0;				
}


/**  Cards Structure **/

// Post meta elemnts - wrappen in mixin
@mixin post-meta() {
	@include knd-nav-caps-text();
	color: $color-main-dark;

    display: inline-block;
	padding: 4px 8px;

    span.category {
        body.plot-public-campaign & {
            @include knd-link-lonely-text();
        }
        
        body.plot-fundraising-org & {
            @include knd-link-lonely-text();
            color: $color-third;
        }
        
    }

	a {
		color: inherit;
		&:hover { text-decoration: underline; }
	}
	
    .date {
        color: $color-text;
      
        body.plot-public-campaign & {
            color: $color-text-contrast;
        }
        
        body.plot-public-campaign.single & {
            color: $color-text;
        }
        
    }
    
    .sep {
        display:inline-block;
        width: 5px;
        height: 5px;
        border: solid 1px $color-page-bg-dark;
        
        body.plot-public-campaign & {
            border-color: $color-text-contrast;
        }
        
        body.plot-public-campaign.single & {
            border-color: $color-text;
        }
        
        border-radius: 5px;
        padding:0px;
        vertical-align:baseline;
        margin:1px 7px 2px 7px;
    }
	
}


/* Post */
// Post card template
.tpl-post {
	background: $color-white;
	transition: all 0.3s;
	
	&:hover {
		background: transparentize($color-white, .3);
		.entry-preview:after { background: rgba(0,0,0, 0.25); }
	}
	
    body.plot-public-campaign & {
        background: $color-second-dark;
    }
	
	a {
		display: block;
		//color: $color-text;
		color: $color-text;
		position:relative;
		height: 100%;
		
		body.plot-public-campaign & {
		  color: $color-text-contrast;
		}
	}
	
	.entry-data {
		padding: 11px $base 0px 23px;
		position: relative;
		margin-bottom:$gut * 2;
	}
	
	.entry-preview {
		@include shadowed-preview();
		
		img {
		  margin-bottom:0px;
		}
	}
	
	.entry-meta {
		@include post-meta();		
		position: absolute;
		bottom: 13px;
		left: 16px;
		z-index: 100;
	}
	
	.entry-title {
		@include knd-head-card-text();
		margin-bottom: $base*0.75;				
		padding-top: 0px;
		hyphens: auto;
	}
	
	.entry-summary {
		@include knd-subhead-text();
		color: $color-text;
	}
}


// Related section variants
.related-card-holder {
    margin-top: $gut;
    
	.related-title {
		@include knd-head-section-text();
	}
	
	a {
	   @include knd-link-related-text();
	   color: $color-main-dark;
	   margin-bottom: 16px;
	}	
}


.related-cards-loop {
    margin-top: $gut / 2;
    a {
        display:block;
    }
}

.tpl-related-post {
	@extend .tpl-post;
	
	&:hover {
				
	}
	
	.entry-data {
        padding: 21px 33px 0px 33px;
        position: relative;
        margin-bottom: $gut * 1.6;
	}
	
	.entry-meta {
        background:none;
        bottom: 13px;
        left: 26px;
        z-index: 100;
	}
	
	.entry-title {
		font-size: 1.5em;
		line-height: 1.1;
		margin-top: 0 !important;
	}
}


/** Programms **/
.tpl-project {
    transition: all 0.3s;
    
    &:hover {
        .entry-preview:after { background: rgba(0,0,0, 0.3); }
    }   
    
    a {
        display: block;
        color: $color-text-contrast;
        position:relative;
        height: 100%;
    }
    
    .entry-preview {
        @include shadowed-preview();
        
        img {
          margin-bottom:0px;
        }
    }
    
    .entry-title {
        @include knd-head-card-text();
        margin-bottom: $base*0.75;
        padding: 24px 0px 0px 0px;
        hyphens: auto;
    }
}


/** People **/
.tpl-person {
	margin-bottom: $gut;
	background: $color-page-bg;
	transition: background 0.3s;
		
	&.linked:hover {

	}
	
	.entry-title {		
		@include knd-head-card-text();
		line-height: 1.2;
		margin-bottom: 12px; 
	}
	
	.entry-preview {
		@include shadowed-preview();
	}
	
	.entry-meta {		
		@include knd-subhead-text();
		line-height: 1.4;
		text-transform: none;
        color: $color-text-light;
		
		a[href *= "tel"] {
			color: $color-text;
			cursor: default;
			&:hover {  color: $color-text; }
		}
	}
	
	.entry-data {
		padding: $gut/2;
	}
	
	.people-cards-shortcode &:hover {
		background: $color-page-bg;	
	}
	
	.people-cards-shortcode & .entry-preview:after {
		display:none;
	}
	
	.people-cards-shortcode & .entry-data {
		padding-bottom: 0;
	}
}


/** Partners **/
.tpl-org {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 40%;
	margin-bottom: $gut;
	text-align: center;
	
	@include for(small-screens) {
		padding-bottom: 60%;
	}
	
	.logo-link {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		
		
		span {
			display: inline-block;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			line-height: 130px;
		}
	}
	
	img {
		max-width: 100%;
		max-height: 100%;
		vertical-aling: middle;
		width: auto;
		height: auto;
	}
}


/* Search card */
.tpl-search {
    margin-bottom: $gut;

    a {
        color: $color-text;
    }

    .entry-title {
        @include knd-body-title-text();             
        padding-top: 0px;
        hyphens: auto;
        color: $color-text;
        margin-bottom: 0;
    }

    .entry-meta {
        font-size: .85em;
        color: $color-third;
        margin-top: 2px;
        margin-bottom: 1em;
    }
    
    .entry-summary {
        @include knd-body-text();
        color: $color-text;
    }
}


/*  Archive layouts */
.cards-holder.archive-post-list {
    padding-top:0px;
    
    &.next-page {
        padding-top: $gut;
    }
}

.listing-bg {
   background: $color-gray-light;
}

.featured-post {
    
   margin-top: 70px;
   margin-bottom: -70px;
   
   .cards-loop {
       transform: translateY(-70px);
   } 
}

// paging 
.paging {
    @include knd-link-paging-text();
    padding: $base/1.5 0 $base * 3.2 0;
    text-align: center;
    
    .page-numbers {
        display: inline-block;
        color: $color-text;
        padding: 0px 10px;
        transition: all 0.3;
        
        &:hover, &:focus, &:active {
            color: $color-page-bg;
            background: $color-main;
        }
        &.current {
            color: $color-page-bg;
            background: $color-main;
        }
    }
    
    &:empty { display: none; }
}

