/** == Pages (_pages.scss) == **/
// Styles of elements used on static pages 

/** Parts **/

.page-header {
	text-align: center;
	padding: $gut 0;
}

.page-title {
	@include knd-head-page();
}

.page-intro {
	margin-top: $gut/2;
	@include knd-head-large-text(); 
}

.page-content {
	padding-bottom: $gut;
	min-height: 60vh;
}

.page-crumb {
	text-align: center;
	@include knd-head-tiny-text();
	margin-bottom: $gut/4;
	a {
		color: $color-main;
		&:hover, &:focus, &:active { color: $color-main-dark; }
	}
}

@mixin page-section-margin() {
	margin: $gut 0;
	@include for(small-screens) {
		margin: $gut*1.2 0;
	}
}

.knd-image-section {
	@include page-section-margin();
	position: relative;
	z-index: 1;
	&:first-child {
		margin-top: 0;
	}
}

.knd-img-bg {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 0;
	padding-bottom: 60%;
	background-color: $color-text;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover; 
}

.knd-section-extend {
	margin-left: -11%;
	margin-right: -11%;

	@media screen and (min-width: 400px) { 
        margin-left: -40px;
		margin-right: -40px; 
    }

    @include for(medium-screens) {
    	margin-left: calc(400px - 50vw);
    	margin-right: calc(400px - 50vw);
    }

	@include for(large-screens) {
		margin-left: -26.6%;
		margin-right: -26.6%;
	}
}

.knd-section-extend-on-large {
	
    @include for(medium-screens) {
    	margin-left: calc(400px - 50vw);
    	margin-right: calc(400px - 50vw);
    }

	@include for(large-screens) {
		margin-left: -26.6%;
		margin-right: -26.6%;
	}
}

.mark-title { 
	@include knd-head-main-text();
	margin-bottom: $base;
}

.mark-text { 
	@include knd-body-text();
}

.mark-over {
	.knd-section-extend {
		z-index: 1;
		position: relative;
	}

	.knd-img-bg {
		z-index: 1;
		min-height: 320px;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			z-index: 5;
			position: absolute;
			bottom: 0;
			left: 0;
			background: rgba(0,0,0, 0.35);
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.18+27,0.31+51,0.65+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 27%, rgba(0,0,0,0.31) 51%, rgba(0,0,0,0.7) 100%); 
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 27%,rgba(0,0,0,0.31) 51%,rgba(0,0,0,0.7) 100%); 
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 27%,rgba(0,0,0,0.31) 51%,rgba(0,0,0,0.7) 100%); 
		}
	}

	.kng-img-mark { 
		z-index: 10;
		padding-bottom: $gut/2;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);

		@include for(small-screens) {
			padding-bottom: $gut;
		}
	}

	.mark-title { 
		color: $color-white;
	}

	.mark-text { 
		color: $color-white;
	}
}

.mark-under {
	.kng-img-mark { margin-top: $base; }

	.mark-title { 
		color: $color-text;
	}

	.mark-text { 

	}
}

.colored {
	.knd-img-bg:after { display: none; }

	.kng-img-mark {
		background: $color-main;
		padding: $gut;
	}

	.mark-title, .mark-text {
		color: $color-white; 
	}

	&.mark-over {
		.kng-img-mark {
			top: auto;
			transform: none;
			bottom: 0;
		}
	}
}

/* key phrase */
.knd-key-phrase {
	text-align: center;
	@include page-section-margin();

	h5 {
		@include knd-head-tiny-text();
		margin-bottom: $gut/4;
	}

	h3 {
		@include knd-head-exlarge-text();
		margin: 0;
	}
}

/* intext CTA */
.knd-intext-cta {
	@include page-section-margin();
	text-align: center;

	.knd-section-extend {
		background: $color-second;
		padding: $gut;

		@include for(medium-screens) {
			padding: $gut*1.2 $gut*2;
		}
	}

	h5 {
		@include knd-head-tiny-text();
		margin-bottom: $gut/4;
	}

	h3 {
		@include knd-head-exlarge-text();
		margin: 0 0 $gut 0;
	}

	.cta-button {
		a {
            @include button();
            @include knd-button-caption-text();
            padding: 16px 50px;
            text-decoration: none;
            cursor: pointer;
		}
	}
}
 
.knd-links {
	margin: $inpage-margin-base 0 $inpage-margin-base*1.5;

	&.center { text-align: center; }
	&.right { text-align: right; }

	a {
        display: inline-block;
        font-weight: bold; 
        margin-right: 1em;
	}
}

.err-404-text {
	text-align: center;
	margin-bottom: 2em; 
}

.err404-content {
	min-height: 70vh;
}

