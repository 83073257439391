/** == Grid (_grid.scss) == */
// Common layout rules and flex-box based grid

*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; }



/* Hide utp/after some break point  */
.hide-upto-small {
	@include upto(small-screens) { display: none;}
}
.hide-upto-medium {
	@include upto(medium-screens) { display: none;}
}
.hide-upto-large {
	@include upto(large-screens) { display: none;}
}


.hide-on-small {
	@include for(small-screens) { display: none;}
}
.hide-on-medium {
	@include for(medium-screens) { display: none;}
}
.hide-on-large {
	@include for(large-screens) { display: none;}
}


/* Support for no JS case */
.no-js .no-js-hide-upto-small {
    @include upto(small-screens) { display: none;}
}

.no-js .no-js-hide-upto-medium {
    @include upto(medium-screens) { display: none;}
}

.no-js .no-js-hide-upto-large {
    @include upto(large-screens) { display: none;}
}



/* Flexbox grid */
.flex-row {
    
    margin-left: -1 * $gut/2;
    margin-right: -1 * $gut/2;
    

    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    &.centered      { justify-content: center; }
    &.start         { justify-content: flex-start; }
    &.space-between { justify-content: space-between; }
    &.align-top     { align-items: flex-start; }
    &.align-bottom  { align-items: flex-end; }
    &.align-center  { align-items: center; }
    &.row-reverse   { flex-direction: row-reverse; }
}


.flex-cell {
    flex: 0 1 auto;
    position: relative;
    min-height: 1px;

    margin: 0 $gut/2 $gut;
    width: calc(100% - #{$gut});
}


// mobile screen
@for $i from 1 through 12 {
    .flex-mf-#{$i} {
        $w: percentage($i/12);
        width: calc(#{$w} - #{$gut});
    }
}

.flex-mf-col-5 {
    width: calc(20% - #{$gut});
}


// small screens
@include for(small-screens) {
    @for $i from 1 through 12 {
        .flex-sm-#{$i} {
            $w: percentage($i/12);
            width: calc(#{$w} - #{$gut});
        }
    }

    .flex-sm-col-5 {
        width: calc(20% - #{$gut});
    }
}


//medium-screens
@include for(medium-screens) {
    @for $i from 1 through 12 {
        .flex-md-#{$i} {
            $w: percentage($i/12);
            width: calc(#{$w} - #{$gut});
        }
    }

    .flex-md-col-5 {
        width: calc(20% - #{$gut});
    }
}


//large screen
@include for(large-screens) {
    @for $i from 1 through 12 {
        .flex-lg-#{$i} {
            $w: percentage($i/12);
            width: calc(#{$w} - #{$gut});
        }
    }

    .flex-lg-col-5 {
        width: calc(20% - #{$gut});
    }
}


/** Containers **/
.container {
    @include container-base();
    max-width: $full_cont; //1104
}

.container-text {
    @include container-base();
    max-width: $page_cont; //720
}

.text-column {
    @include for(medium-screens) {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }
}


