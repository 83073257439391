@charset "UTF-8";
/** == Kandinsky-specific widgets (_widgetsn.scss) == */
/** Colors **/
/** == Mixins (_mixins.scss) == **/
/** Utilities **/
/* Media queries */
/** Global layout presets **/
/** Post content elements **/
/* Elements */
/** == Typography (_mixins-typography.scss) == **/
/** == Fonts (_fonts.scss) == **/
@font-face {
  font-family: 'SourceSansPro';
  src: local("☺"), url("../fonts/SourceSansPro-Regular-Cyr.woff2") format("woff2"), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local("☺"), url("../fonts/SourceSansPro-Regular-Lat.woff2") format("woff2"), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local("☺"), url("../fonts/SourceSansPro-SemiBold-Cyr.woff2") format("woff2"), url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'SourceSansPro';
  src: local("☺"), url("../fonts/SourceSansPro-SemiBold-Lat.woff2") format("woff2"), url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-Regular-Cyr.woff2") format("woff2"), url("../fonts/Exo2-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-Regular-Lat.woff2") format("woff2"), url("../fonts/Exo2-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-SemiBold-Cyr.woff2") format("woff2"), url("../fonts/Exo2-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-SemiBold-Lat.woff2") format("woff2"), url("../fonts/Exo2-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-ExtraBold-Cyr.woff2") format("woff2"), url("../fonts/Exo2-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Exo2';
  src: local("☺"), url("../fonts/Exo2-ExtraBold-Lat.woff2") format("woff2"), url("../fonts/Exo2-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/** == Reset (_reset.scss) == **/
/*  Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

/*  Normalize.css */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus,
a:hover,
a:active {
  outline: 0;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
iframe {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

sub,
sup {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: .5ex;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

img[alt] {
  font-size: 10px;
  line-height: inherit;
  color: #666;
  font-family: inherit;
}

a img {
  border: 0;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

/** Forms **/
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type=search] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
}

textarea {
  overflow: auto;
  padding-left: 3px;
  vertical-align: top;
  width: 100%;
}

/** == Texts (_text.scss) == */
/* Screen-reader only text */
.screen-reader-text {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* text alignment */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* selections */
a:link {
  -webkit-tap-highlight-color: var(--knd-color-main-dark);
  -webkit-tap-highlight-color: var(--knd-color-main-dark);
}

body:not(#tinymce) ::-webkit-selection {
  opacity: 1;
  background: var(--knd-color-main-dark);
  color: #ffffff;
  text-shadow: none;
}

body:not(#tinymce) ::-moz-selection {
  opacity: 1;
  background: var(--knd-color-main-dark);
  color: #ffffff;
  text-shadow: none;
}

body:not(#tinymce) ::selection {
  opacity: 1;
  background: var(--knd-color-main-dark);
  color: #ffffff;
  text-shadow: none;
}

/** Post content elements  **/
.the-content a {
  color: var(--knd-color-main-dark);
  border-bottom: 1px solid var(--knd-color-main);
  transition: all .35s;
}

.the-content a:hover, .the-content a:focus, .the-content a:active {
  color: var(--knd-color-main-dark);
  border-bottom-color: var(--knd-color-main-dark);
}

.the-content h2, .the-content h3, .the-content h4, .the-content h5, .the-content h6 {
  margin: 31.2px 0 16.9px;
  color: var(--knd-page-text-color);
}

.the-content h2:first-child, .the-content h3:first-child, .the-content h4:first-child, .the-content h5:first-child, .the-content h6:first-child {
  margin-top: 0;
}

.the-content h2:last-child, .the-content h3:last-child, .the-content h4:last-child, .the-content h5:last-child, .the-content h6:last-child {
  margin-bottom: 0;
}

.the-content h2 strong, .the-content h2 b, .the-content h3 strong, .the-content h3 b, .the-content h4 strong, .the-content h4 b, .the-content h5 strong, .the-content h5 b, .the-content h6 strong, .the-content h6 b {
  font-weight: normal;
}

.the-content h2, .the-content h3 {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .the-content h2, .the-content h3 {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .the-content h2, .the-content h3 {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .the-content h2, .the-content h3 {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .the-content h2, .the-content h3 {
    font-size: 36px;
  }
}

.the-content h4 {
  font-size: 1.2em;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.44;
  font-weight: 600;
  color: var(--knd-page-text-color);
}

.the-content h5 {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
}

.the-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

.the-content strong, .the-content b {
  color: var(--knd-page-text-color);
}

.the-content em {
  font-style: italic;
  line-height: 1.5;
}

.the-content ins {
  text-decoration: none;
  display: inline-block;
  background: #E7F3FF;
  padding: 0 8px;
}

.the-content h2 + h3, .the-content h2 + h4, .the-content h2 + h5, .the-content h2 + h6,
.the-content h3 + h4, .the-content h3 + h5, .the-content h3 + h6,
.the-content h4 + h5, .the-content h4 + h6,
.the-content h5 + h6 {
  margin-top: 13px;
}

.the-content p, .the-content iframe, .the-content blockquote {
  margin-bottom: 26px;
}

.the-content p:last-child, .the-content iframe:last-child, .the-content blockquote:last-child {
  margin-bottom: 0;
}

.the-content figure {
  margin: 26px 0;
}

.the-content figure:last-child {
  margin-bottom: 0;
}

.the-content figure:first-child {
  margin-top: 0;
}

.the-content ul {
  margin: 26px 0 26px 1.5em;
}

.the-content ul li {
  position: relative;
  padding-left: 20px;
}

.the-content ul li:before {
  content: '–';
  display: inline-block;
  line-height: inherit;
  position: absolute;
  left: 0;
  top: 0;
}

.the-content ul li li {
  padding-left: 0;
  break-inside: avoid;
}

.the-content ul li li:before {
  display: none;
}

.the-content ul:first-child {
  margin-top: 0;
}

.the-content ul ul {
  list-style: circle;
}

.the-content ul ul ul {
  list-style: square;
}

.the-content ol {
  list-style: decimal;
  margin: 26px 0 26px 1.5em;
}

.the-content ol:first-child {
  margin-top: 0;
}

.the-content ol ol {
  list-style: lower-alpha;
}

.the-content ol ol ol {
  list-style: lower-roman;
}

.the-content ul ul,
.the-content ul ol,
.the-content ol ul,
.the-content ol ol {
  margin-bottom: 0;
}

.the-content ul ul:first-child,
.the-content ul ol:first-child,
.the-content ol ul:first-child,
.the-content ol ol:first-child {
  margin-top: 13px;
}

.the-content li {
  margin-bottom: 13px;
}

.the-content li:last-child {
  margin-bottom: 0;
}

.the-content ul + p,
.the-content ol + p {
  margin-top: 26px;
}

.the-content blockquote {
  font-style: italic;
  position: relative;
  padding-left: 34px;
  margin-top: 52px;
}

.the-content blockquote:before {
  content: '“';
  display: inline-block;
  font-size: 70px;
  line-height: 1;
  position: absolute;
  left: -12px;
  top: -12px;
  color: var(--knd-color-main);
}

.the-content blockquote:first-child {
  margin-top: 0;
}

.the-content blockquote:last-child {
  margin-bottom: 0;
}

.the-content pre {
  margin-bottom: 26px;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, monospace;
  font-size: 0.8em;
  line-height: 1.75;
}

.the-content pre:last-child {
  margin-bottom: 0;
}

.the-content .wp-caption {
  max-width: 100%;
  margin-bottom: 26px;
}

.the-content .wp-caption.aligncenter {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .the-content .wp-caption.alignleft {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
  }
  .the-content .wp-caption.alignright {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
  }
}

.the-content .wp-caption img {
  max-width: 100%;
  height: auto;
}

.the-content .video-caption {
  color: var(--knd-page-text-color-light);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: -8px;
  margin-bottom: 26px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .the-content .video-caption {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .the-content .video-caption {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .the-content .video-caption {
    font-size: 14px;
  }
}

.the-content .video-caption .svg-icon {
  fill: var(--knd-page-text-color-light);
  width: 15px;
  height: 19px;
  margin-right: 8px;
  vertical-align: bottom;
}

.the-content .wp-caption-text {
  color: var(--knd-page-text-color-light);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 12px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .the-content .wp-caption-text {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .the-content .wp-caption-text {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .the-content .wp-caption-text {
    font-size: 14px;
  }
}

.the-content .wp-caption-text .svg-icon {
  fill: var(--knd-page-text-color-light);
  width: 15px;
  height: 19px;
  margin-right: 8px;
  vertical-align: bottom;
}

.the-content p + video-caption {
  margin-top: 0;
}

.the-content a[href].alignleft {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .the-content a[href].alignleft {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
  }
}

.the-content a[href].alignleft img {
  max-width: 100%;
  height: auto;
}

.the-content a[href].alignright {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .the-content a[href].alignright {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
  }
}

.the-content a[href].alignright img {
  max-width: 100%;
  height: auto;
}

.the-content img.alignleft,
.the-content img[align="left"] {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .the-content img.alignleft,
  .the-content img[align="left"] {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
    height: auto;
  }
}

.the-content img.alignright,
.the-content img[align="right"] {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .the-content img.alignright,
  .the-content img[align="right"] {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
    height: auto;
  }
}

.the-content pre, .the-content code {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .the-content pre, .the-content code {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .the-content pre, .the-content code {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .the-content pre, .the-content code {
    font-size: 19px;
  }
}

/* Default WP Galleries */
.the-content .gallery,
.gallery {
  margin-top: 26px;
  margin-bottom: 26px;
  margin-right: -6px;
  margin-left: -6px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.the-content .gallery .gallery-item,
.gallery .gallery-item {
  padding: 6px;
  margin: 0;
  flex: 1 1 auto;
}

.the-content .gallery .gallery-item .wp-caption-text,
.gallery .gallery-item .wp-caption-text {
  display: none;
}

.the-content .gallery .gallery-item a,
.gallery .gallery-item a {
  display: block;
  border: none;
  outline: none;
  text-decoration: none;
}

.the-content .gallery .gallery-item img,
.gallery .gallery-item img {
  width: 100%;
  height: auto;
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.33333%;
}

.gallery-columns-4 .gallery-item {
  width: 25%;
}

.gallery-columns-5 .gallery-item {
  width: 20%;
}

.gallery-columns-6 .gallery-item {
  width: 16.66667%;
}

.gallery-columns-7 .gallery-item {
  width: 14.28571%;
}

.gallery-columns-8 .gallery-item {
  width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  width: 11.11111%;
}

/** == Grid (_grid.scss) == */
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Hide utp/after some break point  */
@media screen and (max-width: 479px) {
  .hide-upto-small {
    display: none;
  }
}

@media screen and (max-width: 799px) {
  .hide-upto-medium {
    display: none;
  }
}

@media screen and (max-width: 1103px) {
  .hide-upto-large {
    display: none;
  }
}

@media screen and (min-width: 480px) {
  .hide-on-small {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .hide-on-medium {
    display: none;
  }
}

@media screen and (min-width: 1104px) {
  .hide-on-large {
    display: none;
  }
}

/* Support for no JS case */
@media screen and (max-width: 479px) {
  .no-js .no-js-hide-upto-small {
    display: none;
  }
}

@media screen and (max-width: 799px) {
  .no-js .no-js-hide-upto-medium {
    display: none;
  }
}

@media screen and (max-width: 1103px) {
  .no-js .no-js-hide-upto-large {
    display: none;
  }
}

/* Flexbox grid */
.flex-row {
  margin-left: -24px;
  margin-right: -24px;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;
}

.flex-row.centered {
  justify-content: center;
}

.flex-row.start {
  justify-content: flex-start;
}

.flex-row.space-between {
  justify-content: space-between;
}

.flex-row.align-top {
  align-items: flex-start;
}

.flex-row.align-bottom {
  align-items: flex-end;
}

.flex-row.align-center {
  align-items: center;
}

.flex-row.row-reverse {
  flex-direction: row-reverse;
}

.flex-cell {
  flex: 0 1 auto;
  position: relative;
  min-height: 1px;
  margin: 0 24px 48px;
  width: calc(100% - 48px);
}

.flex-mf-1 {
  width: calc(8.33333% - 48px);
}

.flex-mf-2 {
  width: calc(16.66667% - 48px);
}

.flex-mf-3 {
  width: calc(25% - 48px);
}

.flex-mf-4 {
  width: calc(33.33333% - 48px);
}

.flex-mf-5 {
  width: calc(41.66667% - 48px);
}

.flex-mf-6 {
  width: calc(50% - 48px);
}

.flex-mf-7 {
  width: calc(58.33333% - 48px);
}

.flex-mf-8 {
  width: calc(66.66667% - 48px);
}

.flex-mf-9 {
  width: calc(75% - 48px);
}

.flex-mf-10 {
  width: calc(83.33333% - 48px);
}

.flex-mf-11 {
  width: calc(91.66667% - 48px);
}

.flex-mf-12 {
  width: calc(100% - 48px);
}

.flex-mf-col-5 {
  width: calc(20% - 48px);
}

@media screen and (min-width: 480px) {
  .flex-sm-1 {
    width: calc(8.33333% - 48px);
  }
  .flex-sm-2 {
    width: calc(16.66667% - 48px);
  }
  .flex-sm-3 {
    width: calc(25% - 48px);
  }
  .flex-sm-4 {
    width: calc(33.33333% - 48px);
  }
  .flex-sm-5 {
    width: calc(41.66667% - 48px);
  }
  .flex-sm-6 {
    width: calc(50% - 48px);
  }
  .flex-sm-7 {
    width: calc(58.33333% - 48px);
  }
  .flex-sm-8 {
    width: calc(66.66667% - 48px);
  }
  .flex-sm-9 {
    width: calc(75% - 48px);
  }
  .flex-sm-10 {
    width: calc(83.33333% - 48px);
  }
  .flex-sm-11 {
    width: calc(91.66667% - 48px);
  }
  .flex-sm-12 {
    width: calc(100% - 48px);
  }
  .flex-sm-col-5 {
    width: calc(20% - 48px);
  }
}

@media screen and (min-width: 800px) {
  .flex-md-1 {
    width: calc(8.33333% - 48px);
  }
  .flex-md-2 {
    width: calc(16.66667% - 48px);
  }
  .flex-md-3 {
    width: calc(25% - 48px);
  }
  .flex-md-4 {
    width: calc(33.33333% - 48px);
  }
  .flex-md-5 {
    width: calc(41.66667% - 48px);
  }
  .flex-md-6 {
    width: calc(50% - 48px);
  }
  .flex-md-7 {
    width: calc(58.33333% - 48px);
  }
  .flex-md-8 {
    width: calc(66.66667% - 48px);
  }
  .flex-md-9 {
    width: calc(75% - 48px);
  }
  .flex-md-10 {
    width: calc(83.33333% - 48px);
  }
  .flex-md-11 {
    width: calc(91.66667% - 48px);
  }
  .flex-md-12 {
    width: calc(100% - 48px);
  }
  .flex-md-col-5 {
    width: calc(20% - 48px);
  }
}

@media screen and (min-width: 1104px) {
  .flex-lg-1 {
    width: calc(8.33333% - 48px);
  }
  .flex-lg-2 {
    width: calc(16.66667% - 48px);
  }
  .flex-lg-3 {
    width: calc(25% - 48px);
  }
  .flex-lg-4 {
    width: calc(33.33333% - 48px);
  }
  .flex-lg-5 {
    width: calc(41.66667% - 48px);
  }
  .flex-lg-6 {
    width: calc(50% - 48px);
  }
  .flex-lg-7 {
    width: calc(58.33333% - 48px);
  }
  .flex-lg-8 {
    width: calc(66.66667% - 48px);
  }
  .flex-lg-9 {
    width: calc(75% - 48px);
  }
  .flex-lg-10 {
    width: calc(83.33333% - 48px);
  }
  .flex-lg-11 {
    width: calc(91.66667% - 48px);
  }
  .flex-lg-12 {
    width: calc(100% - 48px);
  }
  .flex-lg-col-5 {
    width: calc(20% - 48px);
  }
}

/** Containers **/
.container {
  position: relative;
  width: calc(100% - 54px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1104px;
}

@media screen and (min-width: 400px) {
  .container {
    width: calc(100% - 80px);
  }
}

@media screen and (min-width: 1104px) {
  .container {
    width: 92%;
  }
}

.container-text {
  position: relative;
  width: calc(100% - 54px);
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

@media screen and (min-width: 400px) {
  .container-text {
    width: calc(100% - 80px);
  }
}

@media screen and (min-width: 1104px) {
  .container-text {
    width: 92%;
  }
}

@media screen and (min-width: 800px) {
  .text-column {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

/** == General (_general.scss) == */
html {
  background-color: #ffffff;
}

body {
  background: var(--knd-page-bg-color);
  color: var(--knd-page-text-color);
  text-rendering: optimizeLegibility;
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
}

@media (min-width: 440px) and (max-width: 768px) {
  body {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 19px;
  }
}

body > svg {
  display: none;
}

a {
  text-decoration: none;
  outline: none;
}

.svg-icon, .svg-pic {
  display: inline-block;
}

.main-content {
  overflow: hidden;
  min-height: 60vh;
}

.heading h1 {
  padding: 40px 0px 28.23529px 0px;
}

.section-title {
  font-size: 38px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1;
  letter-spacing: .03em;
  font-weight: 800;
}

@media (min-width: 440px) and (max-width: 768px) {
  .section-title {
    font-size: calc( 38px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .section-title {
    font-size: calc( 41px + 3 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .section-title {
    font-size: 44px;
  }
}

/** == Header (_header.scss) == */
/* Header layout */
.site-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--knd-page-bg-color);
  border-bottom: 1px solid var(--knd-page-bg-color);
  transition: opacity 0.3s;
}

body.plot-public-campaign .site-header {
  background: var(--knd-color-third);
  border-bottom-color: var(--knd-color-third);
}

.site-header.fixed-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  opacity: 1;
}

@media screen {
  .admin-bar .site-header {
    top: 46px;
  }
}

@media screen and (min-width: 600px) {
  .admin-bar .site-header {
    top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .admin-bar .site-header {
    top: 32px;
  }
}

.site-header.invisible {
  opacity: 0;
}

.site-header.hard-top-margin-for-small-screen {
  top: 46px;
}

@media screen and (max-width: 1103px) {
  #wp-admin-bar-kandinsky-main {
    display: none;
  }
}

@media screen and (max-width: 1103px) {
  #wp-admin-bar-leyka-toolbar-menu {
    display: none;
  }
}

@media screen and (max-width: 1103px) {
  #wpadminbar #wp-admin-bar-wpseo-menu.menupop {
    display: none;
  }
}

.site-content {
  padding-top: 77px;
}

@media screen and (min-width: 800px) {
  .site-content {
    padding-top: 101px;
  }
}

.site-panel-row {
  display: flex;
  flex-flow: row nowrap;
}

.site-panel-cell {
  height: 76px;
}

@media screen and (min-width: 800px) {
  .site-panel-cell {
    height: 100px;
  }
}

.site-panel-cell.site-details {
  flex: 1 1 auto;
}

.site-panel-cell.donate {
  flex: 0 0 auto;
}

@media screen and (max-width: 799px) {
  .logomod-text_only .site-panel-cell.donate,
  .logomod-image_only .site-panel-cell.donate {
    display: none;
  }
}

.site-panel-cell.menu {
  width: 76px;
  flex: 0 0 auto;
}

.site-panel-cell.site-branding {
  flex: 1 1 auto;
  min-width: 1px;
}

.site-logo {
  display: block;
  height: 100%;
  padding-left: 18.04511px;
}

@media screen and (max-width: 1400px) {
  .site-logo {
    padding-left: 17px;
  }
}

.logo-image-only {
  padding-top: 17px;
}

.logo-image-only img {
  height: 42px;
  width: auto;
}

@media screen and (min-width: 800px) {
  .logo-image-only img {
    height: 66px;
  }
}

.logo-text-only {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 799px) {
  .logo-text-only h2 {
    display: none;
  }
}

.logo-complex {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 799px) {
  .logo-complex .text {
    display: none;
  }
}

.logo-complex .logo {
  flex: 0 1 auto;
  height: 42px;
}

@media screen and (min-width: 800px) {
  .logo-complex .logo {
    height: 66px;
  }
}

.logo-complex img {
  height: 100%;
  width: auto;
}

.logo-complex .text {
  flex: 1 1 auto;
  padding-left: 18.04511px;
}

.site-details-cell {
  min-width: 1px;
  min-height: 1px;
}

.site-details-cell p {
  display: none;
}

@media screen and (min-width: 1104px) {
  .site-details-cell {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    height: 100px;
    margin-right: 17px;
  }
  .site-details-cell p {
    display: block;
    max-width: 400px;
  }
}

/* Styling of header elements */
.logo-name {
  text-transform: uppercase;
  line-height: 1;
  color: var(--knd-page-text-color);
}

body.plot-public-campaign .logo-name {
  color: var(--knd-text1-color);
}

h1.logo-name {
  font-family: "Exo2", Arial, sans-serif;
  font-size: 21px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.2;
  letter-spacing: .03em;
  font-weight: 800;
}

@media (min-width: 440px) and (max-width: 768px) {
  h1.logo-name {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  h1.logo-name {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  h1.logo-name {
    font-size: 25px;
  }
}

h2.logo-name {
  padding-top: 4px;
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 440px) and (max-width: 768px) {
  h2.logo-name {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  h2.logo-name {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  h2.logo-name {
    font-size: 14px;
  }
}

.site-details {
  text-align: right;
}

.site-details p {
  color: var(--knd-page-text-color);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

@media (min-width: 440px) and (max-width: 768px) {
  .site-details p {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .site-details p {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .site-details p {
    font-size: 14px;
  }
}

body.plot-public-campaign .site-details p {
  color: var(--knd-text1-color);
}

.site-details p a {
  color: inherit;
  display: inline-block;
  border-bottom: 1px solid;
}

.trigger-button {
  cursor: pointer;
}

.trigger-button.donate {
  position: relative;
  margin-right: 1px;
  z-index: 10;
}

.trigger-button.donate a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
  margin: 17px 0 0 17px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .trigger-button.donate a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .trigger-button.donate a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .trigger-button.donate a {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .trigger-button.donate a {
    padding: 12px 35px;
  }
}

.trigger-button.donate a:visited {
  color: #ffffff;
}

.trigger-button.donate a:hover, .trigger-button.donate a:focus, .trigger-button.donate a:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}

@media screen and (min-width: 800px) {
  .trigger-button.donate a {
    margin-top: 26px;
  }
}

.trigger-button.menu {
  text-align: center;
  cursor: pointer;
}

.trigger-button.menu #trigger_menu {
  display: block;
  width: 76px;
  height: 76px;
  padding: 27px;
  text-align: center;
  background: transparent;
}

.trigger-button.menu #trigger_menu .svg-icon {
  width: 32px;
  height: 22px;
}

.trigger-button.menu .svg-icon {
  width: 18px;
  height: 18px;
  fill: var(--knd-page-text-color);
  pointer-events: none;
}

body.plot-public-campaign .trigger-button.menu .svg-icon {
  fill: var(--knd-color-main);
}

.trigger-button.menu:hover .svg-icon {
  fill: var(--knd-color-main-dark);
}

body.plot-public-campaign .trigger-button.menu:hover .svg-icon {
  fill: var(--knd-color-main-dark);
}

@media screen and (min-width: 800px) {
  .trigger-button.menu #trigger_menu {
    margin-top: 12px;
  }
}

/** Main menu **/
.nav-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.4);
}

body.plot-public-campaign .nav-overlay {
  background: rgba(255, 255, 255, 0.6);
}

.menu-open .nav-overlay {
  display: block;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.searchform {
  width: 100%;
}

.searchform .action {
  display: none;
}

.searchform input {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: normal;
  text-transform: none;
  padding: 18px 24px;
  color: var(--knd-page-text-color);
  background: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid var(--knd-page-bg-color-dark);
}

.searchform input::placeholder {
  color: var(--knd-page-text-color-light);
}

.searchform input:focus {
  box-shadow: inset 0px 1px 2px rgba(50, 50, 50, 0.08);
  background: #ffffff;
  color: #000000;
  padding-bottom: 17px;
  border-bottom: 2px solid var(--knd-color-main);
}

.site-nav {
  display: block;
  z-index: 1020;
  position: fixed;
  top: 0;
  right: -290px;
  width: 290px;
  height: 100%;
  background: var(--knd-page-bg-color);
  overflow-y: scroll;
  transition: all 0.4s;
}

body.plot-public-campaign .site-nav {
  background: var(--knd-color-third);
  color: var(--knd-text1-color);
}

@media screen and (min-width: 800px) {
  .site-nav {
    right: -310px;
    width: 310px;
  }
}

@media screen and (min-width: 600px) {
  .admin-bar .site-nav {
    top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .admin-bar .site-nav {
    top: 32px;
  }
}

.menu-open .site-nav {
  right: 0;
}

.site-nav .search-holder .svg-icon {
  display: none;
}

.site-nav .knd-social-links {
  padding: 24px;
}

.site-nav .knd-social-links .svg-icon {
  fill: var(--knd-page-text-color);
}

body.plot-public-campaign .site-nav .knd-social-links .svg-icon {
  fill: var(--knd-text1-color);
}

.site-nav-title {
  border-bottom: 1px solid var(--knd-page-bg-color-dark);
  position: relative;
  height: 76px;
}

body.plot-public-campaign .site-nav-title {
  border-color: var(--knd-color-second);
}

@media screen and (min-width: 800px) {
  .site-nav-title {
    height: 100px;
  }
}

.site-nav-title .snt-cell {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 76px;
  max-width: 196px;
  padding-left: 24px;
}

@media screen and (min-width: 800px) {
  .site-nav-title .snt-cell {
    height: 100px;
  }
}

.site-nav-title h1.logo-name {
  font-size: 15px;
  line-height: 16px;
}

.site-nav-title h2.logo-name {
  font-size: 11.5px;
  line-height: 15px;
  text-transform: none;
  padding-top: 6px;
}

.site-nav-title .close {
  background: var(--knd-color-main);
  width: 76px;
  height: 76px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 29px;
}

.site-nav-title .close .svg-icon {
  width: 18px;
  height: 18px;
  fill: #ffffff;
}

.site-nav-title .close:hover {
  background: var(--knd-color-main-dark);
}

@media screen and (min-width: 800px) {
  .site-nav-title .close {
    margin-top: 12px;
  }
}

.main-menu li {
  border-bottom: 1px solid var(--knd-page-bg-color-dark);
  position: relative;
}

body.plot-public-campaign .main-menu li {
  border-color: var(--knd-color-second);
}

.main-menu li > a {
  display: block;
  padding: 19px 24px;
  margin-right: 59px;
  transition: all 0.3s;
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .main-menu li > a {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-menu li > a {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .main-menu li > a {
    font-size: 14px;
  }
}

body.plot-public-campaign .main-menu li > a {
  background: var(--knd-color-third);
  color: var(--knd-text1-color);
}

.main-menu li > a:hover, .main-menu li > a:focus, .main-menu li > a:active {
  background: var(--knd-color-main);
  color: #ffffff;
}

body.plot-public-campaign .main-menu li > a:hover, body.plot-public-campaign .main-menu li > a:focus, body.plot-public-campaign .main-menu li > a:active {
  background: var(--knd-color-main);
  color: var(--knd-text1-color);
}

.main-menu li .submenu-trigger {
  display: none;
}

.main-menu .menu-item-has-children .submenu-trigger {
  display: block;
  border-left: 1px solid var(--knd-page-bg-color-dark);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  cursor: pointer;
  text-align: center;
  width: 59px;
  height: 59px;
  padding: 22.5px;
}

body.plot-public-campaign .main-menu .menu-item-has-children .submenu-trigger {
  border-left-color: var(--knd-color-second);
}

.main-menu .menu-item-has-children .submenu-trigger .svg-icon {
  display: block;
  width: 14px;
  height: 14px;
  fill: var(--knd-page-text-color);
  vertical-align: middle;
}

body.plot-public-campaign .main-menu .menu-item-has-children .submenu-trigger .svg-icon {
  fill: var(--knd-text1-color);
}

.main-menu .menu-item-has-children .submenu-trigger:hover {
  background: var(--knd-color-main);
  border-left-color: var(--knd-color-main);
}

.main-menu .menu-item-has-children .submenu-trigger:hover .svg-icon {
  fill: #ffffff;
}

.main-menu .menu-item-has-children .submenu-trigger .icon-up {
  display: none;
}

.main-menu .menu-item-has-children ul.sub-menu .submenu-trigger {
  display: none;
}

.main-menu ul.sub-menu {
  display: none;
  background: var(--knd-color-main);
  color: #ffffff;
}

.main-menu ul.sub-menu li {
  border: none;
}

.main-menu ul.sub-menu a {
  background: var(--knd-color-main);
  color: #ffffff;
  margin-right: 0;
  transition: all .35s;
}

.main-menu ul.sub-menu a:hover, .main-menu ul.sub-menu a:focus, .main-menu ul.sub-menu a:active {
  opacity: .5;
}

.main-menu li.open .submenu-trigger {
  background: var(--knd-color-main);
  border-left-color: var(--knd-color-main);
}

.main-menu li.open .submenu-trigger .icon-down {
  display: none;
}

.main-menu li.open .submenu-trigger .icon-up {
  display: block;
  fill: #ffffff;
}

.main-menu li.open ul.sub-menu {
  display: block;
}

/** == Footers (_footer.scss) == */
/** Bottom branding pannel with social links **/
.bottom-bar {
  background: #eeeeee;
  padding: 57.2px 0 0;
  color: #000000;
}

body.plot-public-campaign .bottom-bar {
  color: var(--knd-text1-color);
  background: var(--knd-color-third);
}

.bottom-bar .knd-social-links li a .svg-icon {
  fill: #000000;
}

body.plot-public-campaign .bottom-bar .knd-social-links li a .svg-icon {
  fill: var(--knd-text1-color);
}

.bottom-bar h2.logo-name {
  padding-top: 8px;
}

.bottom-branding {
  padding-bottom: 24px;
}

.bottom-branding .flex-cell {
  margin-bottom: 24px;
}

/** Footer **/
.site-footer {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  padding: 10px 0px 30px 0px;
  background: #eeeeee;
  color: #000000;
}

@media (min-width: 440px) and (max-width: 768px) {
  .site-footer {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .site-footer {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .site-footer {
    font-size: 14px;
  }
}

body.plot-public-campaign .site-footer {
  color: var(--knd-text1-color);
  background: var(--knd-color-third);
}

.site-footer a {
  color: var(--knd-color-main-dark);
}

.site-footer a:hover, .site-footer a:focus, .site-footer a:active {
  text-decoration: underline;
  color: var(--knd-color-main);
}

body.plot-fundraising-org .site-footer a {
  color: var(--knd-color-third);
}

body.plot-fundraising-org .site-footer a:hover, body.plot-fundraising-org .site-footer a:focus, body.plot-fundraising-org .site-footer a:active {
  color: var(--knd-color-third-dark);
}

.site-footer p {
  hyphens: none;
  margin-bottom: 13px;
}

.site-footer p:last-child {
  margin-bottom: 0;
}

.site-footer .copy {
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  max-width: 300px;
  opacity: .75;
}

.site-footer .copy a {
  white-space: nowrap;
  color: #000000;
}

body.plot-public-campaign .site-footer .copy {
  color: var(--knd-text1-color);
  opacity: .5;
}

body.plot-public-campaign .site-footer .copy a {
  color: var(--knd-text1-color);
}

.site-footer .widget-area {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.site-footer .widget-bottom {
  flex: 0 0 auto;
  width: calc(100% - 24px);
  padding-bottom: 48px;
}

@media screen and (min-width: 480px) {
  .site-footer .widget-bottom {
    width: calc(50% - 24px);
  }
}

@media screen and (min-width: 800px) {
  .site-footer .widget-bottom:nth-of-type(2), .site-footer .widget-bottom:nth-of-type(3) {
    width: calc(25% - 24px);
  }
}

@media screen and (min-width: 1104px) {
  .site-footer .widget-bottom {
    width: calc(33.3% - 24px);
  }
  .site-footer .widget-bottom.widget_nav_menu {
    width: calc(17% - 24px);
  }
  .site-footer .widget-bottom:nth-of-type(2), .site-footer .widget-bottom:nth-of-type(3) {
    width: calc(15% - 24px);
  }
}

.site-footer .hr {
  margin: 0;
  color: transparent;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

body.plot-public-campaign .site-footer .hr {
  border-bottom: 1px solid var(--knd-color-second);
}

/** Footer credits block */
.footer-credits {
  padding-top: 16px;
}

.knd-brand {
  position: relative;
}

.knd-brand:after {
  content: " ";
  display: table;
  clear: both;
}

.knd-brand .knd-icon {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.knd-brand a {
  float: right;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  text-decoration: none;
}

.knd-brand a:hover {
  text-decoration: none;
}

.knd-brand .knd-banner {
  display: block;
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
}

.knd-brand .support {
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--knd-page-text-color);
  padding-right: 20px;
  width: 104px;
  text-align: right;
  display: none;
  opacity: .75;
}

@media screen and (min-width: 480px) {
  .knd-brand .support {
    display: block;
  }
}

body.plot-public-campaign .knd-brand .support {
  color: var(--knd-text1-color);
  opacity: .5;
}

/** == Social (_social.scss) == */
/** Common settings  **/
/** Social links **/
.knd-social-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

@media screen and (min-width: 800px) {
  .links-right .knd-social-links {
    justify-content: flex-end;
  }
}

.knd-social-links li {
  text-align: center;
}

.knd-social-links li a {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transition: all 0.2s;
  text-decoration: none;
  width: 46px;
  height: 46px;
  padding: 12px 0;
}

.knd-social-links li a span {
  display: none;
}

.knd-social-links li .svg-icon {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
}

/**  Sharing **/
.social-likes:after {
  content: " ";
  display: table;
  clear: both;
}

.social-likes__widget {
  cursor: pointer;
}

.social-likes__widget a {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transition: all 0.2s;
  text-decoration: none;
  width: 44px;
  height: 44px;
  padding: 11px 0;
  padding: 9px 0;
}

.social-likes__widget .sh-text {
  display: none;
}

.social-likes__widget .svg-icon {
  display: inline-block;
  vertical-align: moddle;
  fill: #ffffff;
  vertical-align: middle;
  width: 22px;
  height: 22px;
}

.social-likes__widget_vk a {
  background: #517397;
}

.social-likes__widget_vk a:hover, .social-likes__widget_vk a:focus, .social-likes__widget_vk a:active {
  background: rgba(81, 115, 151, 0.8);
}

.social-likes__widget_vk a:last-of-type {
  border: none;
}

.social-likes__widget_facebook a {
  background: #3B5998;
}

.social-likes__widget_facebook a:hover, .social-likes__widget_facebook a:focus, .social-likes__widget_facebook a:active {
  background: rgba(59, 89, 152, 0.8);
}

.social-likes__widget_facebook a:last-of-type {
  border: none;
}

.social-likes__widget_twitter a {
  background: #1DA1F2;
}

.social-likes__widget_twitter a:hover, .social-likes__widget_twitter a:focus, .social-likes__widget_twitter a:active {
  background: rgba(29, 161, 242, 0.8);
}

.social-likes__widget_twitter a:last-of-type {
  border: none;
}

.social-likes__widget_telegram a {
  background: #2CA5E0;
}

.social-likes__widget_telegram a:hover, .social-likes__widget_telegram a:focus, .social-likes__widget_telegram a:active {
  background: rgba(44, 165, 224, 0.8);
}

.social-likes__widget_telegram a:last-of-type {
  border: none;
}

.social-likes__widget_whatsapp a {
  background: #69BD35;
}

.social-likes__widget_whatsapp a:hover, .social-likes__widget_whatsapp a:focus, .social-likes__widget_whatsapp a:active {
  background: rgba(105, 189, 53, 0.8);
}

.social-likes__widget_whatsapp a:last-of-type {
  border: none;
}

.social-likes__widget_viber a {
  background: #774C98;
}

.social-likes__widget_viber a:hover, .social-likes__widget_viber a:focus, .social-likes__widget_viber a:active {
  background: rgba(119, 76, 152, 0.8);
}

.social-likes__widget_viber a:last-of-type {
  border: none;
}

.social-likes__widget_ok a {
  background: #F58220;
}

.social-likes__widget_ok a:hover, .social-likes__widget_ok a:focus, .social-likes__widget_ok a:active {
  background: rgba(245, 130, 32, 0.8);
}

.social-likes__widget_ok a:last-of-type {
  border: none;
}

/** == Posts listings (_builin.scss) == */
/** Commons **/
.thumbnail-link {
  display: block;
}

.sep {
  padding: 0 4px;
}

.fixed-top {
  position: fixed;
  top: 189px;
}

.fixed-bottom {
  position: absolute;
  bottom: 0;
}

/**  Cards Structure **/
/* Post */
.tpl-post, .tpl-related-post {
  background: #ffffff;
  transition: all 0.3s;
}

.tpl-post:hover, .tpl-related-post:hover {
  background: rgba(255, 255, 255, 0.7);
}

.tpl-post:hover .entry-preview:after, .tpl-related-post:hover .entry-preview:after {
  background: rgba(0, 0, 0, 0.25);
}

body.plot-public-campaign .tpl-post, body.plot-public-campaign .tpl-related-post {
  background: var(--knd-color-second-dark);
}

.tpl-post a, .tpl-related-post a {
  display: block;
  color: var(--knd-page-text-color);
  position: relative;
  height: 100%;
}

body.plot-public-campaign .tpl-post a, body.plot-public-campaign .tpl-related-post a {
  color: var(--knd-text1-color);
}

.tpl-post .entry-data, .tpl-related-post .entry-data {
  padding: 11px 26px 0px 23px;
  position: relative;
  margin-bottom: 96px;
}

.tpl-post .entry-preview, .tpl-related-post .entry-preview {
  width: 100%;
  position: relative;
  z-index: 1;
}

.tpl-post .entry-preview img, .tpl-related-post .entry-preview img {
  position: relative;
  width: 100%;
  z-index: 2;
}

.tpl-post .entry-preview:after, .tpl-related-post .entry-preview:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
  transition: all 0.35s;
}

.tpl-post .entry-preview img, .tpl-related-post .entry-preview img {
  margin-bottom: 0px;
}

.tpl-post .entry-meta, .tpl-related-post .entry-meta {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--knd-color-main-dark);
  display: inline-block;
  padding: 4px 8px;
  position: absolute;
  bottom: 13px;
  left: 16px;
  z-index: 100;
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-post .entry-meta, .tpl-related-post .entry-meta {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-post .entry-meta, .tpl-related-post .entry-meta {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-post .entry-meta, .tpl-related-post .entry-meta {
    font-size: 14px;
  }
}

body.plot-public-campaign .tpl-post .entry-meta span.category, body.plot-public-campaign .tpl-related-post .entry-meta span.category {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 600;
}

@media (min-width: 440px) and (max-width: 768px) {
  body.plot-public-campaign .tpl-post .entry-meta span.category, body.plot-public-campaign .tpl-related-post .entry-meta span.category {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body.plot-public-campaign .tpl-post .entry-meta span.category, body.plot-public-campaign .tpl-related-post .entry-meta span.category {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  body.plot-public-campaign .tpl-post .entry-meta span.category, body.plot-public-campaign .tpl-related-post .entry-meta span.category {
    font-size: 14px;
  }
}

body.plot-fundraising-org .tpl-post .entry-meta span.category, body.plot-fundraising-org .tpl-related-post .entry-meta span.category {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 600;
  color: var(--knd-color-third);
}

@media (min-width: 440px) and (max-width: 768px) {
  body.plot-fundraising-org .tpl-post .entry-meta span.category, body.plot-fundraising-org .tpl-related-post .entry-meta span.category {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body.plot-fundraising-org .tpl-post .entry-meta span.category, body.plot-fundraising-org .tpl-related-post .entry-meta span.category {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  body.plot-fundraising-org .tpl-post .entry-meta span.category, body.plot-fundraising-org .tpl-related-post .entry-meta span.category {
    font-size: 14px;
  }
}

.tpl-post .entry-meta a, .tpl-related-post .entry-meta a {
  color: inherit;
}

.tpl-post .entry-meta a:hover, .tpl-related-post .entry-meta a:hover {
  text-decoration: underline;
}

.tpl-post .entry-meta .date, .tpl-related-post .entry-meta .date {
  color: var(--knd-page-text-color);
}

body.plot-public-campaign .tpl-post .entry-meta .date, body.plot-public-campaign .tpl-related-post .entry-meta .date {
  color: var(--knd-text1-color);
}

body.plot-public-campaign.single .tpl-post .entry-meta .date, body.plot-public-campaign.single .tpl-related-post .entry-meta .date {
  color: var(--knd-page-text-color);
}

.tpl-post .entry-meta .sep, .tpl-related-post .entry-meta .sep {
  display: inline-block;
  width: 5px;
  height: 5px;
  border: solid 1px var(--knd-page-bg-color-dark);
  border-radius: 5px;
  padding: 0px;
  vertical-align: baseline;
  margin: 1px 7px 2px 7px;
}

body.plot-public-campaign .tpl-post .entry-meta .sep, body.plot-public-campaign .tpl-related-post .entry-meta .sep {
  border-color: var(--knd-text1-color);
}

body.plot-public-campaign.single .tpl-post .entry-meta .sep, body.plot-public-campaign.single .tpl-related-post .entry-meta .sep {
  border-color: var(--knd-page-text-color);
}

.tpl-post .entry-title, .tpl-related-post .entry-title {
  font-size: 16px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.25;
  letter-spacing: .01em;
  font-weight: 600;
  margin-bottom: 19.5px;
  padding-top: 0px;
  hyphens: auto;
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-post .entry-title, .tpl-related-post .entry-title {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-post .entry-title, .tpl-related-post .entry-title {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-post .entry-title, .tpl-related-post .entry-title {
    font-size: 19px;
  }
}

.tpl-post .entry-summary, .tpl-related-post .entry-summary {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-post .entry-summary, .tpl-related-post .entry-summary {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-post .entry-summary, .tpl-related-post .entry-summary {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-post .entry-summary, .tpl-related-post .entry-summary {
    font-size: 14px;
  }
}

.related-card-holder {
  margin-top: 48px;
}

.related-card-holder .related-title {
  font-size: 21px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 600;
}

@media (min-width: 440px) and (max-width: 768px) {
  .related-card-holder .related-title {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .related-card-holder .related-title {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .related-card-holder .related-title {
    font-size: 25px;
  }
}

.related-card-holder a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.33;
  font-weight: 400;
  color: var(--knd-color-main-dark);
  margin-bottom: 16px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .related-card-holder a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .related-card-holder a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .related-card-holder a {
    font-size: 19px;
  }
}

.related-cards-loop {
  margin-top: 24px;
}

.related-cards-loop a {
  display: block;
}

.tpl-related-post .entry-data {
  padding: 21px 33px 0px 33px;
  position: relative;
  margin-bottom: 76.8px;
}

.tpl-related-post .entry-meta {
  background: none;
  bottom: 13px;
  left: 26px;
  z-index: 100;
}

.tpl-related-post .entry-title {
  font-size: 1.5em;
  line-height: 1.1;
  margin-top: 0 !important;
}

/** Programms **/
.tpl-project {
  transition: all 0.3s;
}

.tpl-project:hover .entry-preview:after {
  background: rgba(0, 0, 0, 0.3);
}

.tpl-project a {
  display: block;
  color: var(--knd-text1-color);
  position: relative;
  height: 100%;
}

.tpl-project .entry-preview {
  width: 100%;
  position: relative;
  z-index: 1;
}

.tpl-project .entry-preview img {
  position: relative;
  width: 100%;
  z-index: 2;
}

.tpl-project .entry-preview:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
  transition: all 0.35s;
}

.tpl-project .entry-preview img {
  margin-bottom: 0px;
}

.tpl-project .entry-title {
  font-size: 16px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.25;
  letter-spacing: .01em;
  font-weight: 600;
  margin-bottom: 19.5px;
  padding: 24px 0px 0px 0px;
  hyphens: auto;
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-project .entry-title {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-project .entry-title {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-project .entry-title {
    font-size: 19px;
  }
}

/** People **/
.tpl-person {
  margin-bottom: 48px;
  background: var(--knd-page-bg-color);
  transition: background 0.3s;
}

.tpl-person .entry-title {
  font-size: 16px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.25;
  letter-spacing: .01em;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 12px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-person .entry-title {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-person .entry-title {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-person .entry-title {
    font-size: 19px;
  }
}

.tpl-person .entry-preview {
  width: 100%;
  position: relative;
  z-index: 1;
}

.tpl-person .entry-preview img {
  position: relative;
  width: 100%;
  z-index: 2;
}

.tpl-person .entry-preview:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
  transition: all 0.35s;
}

.tpl-person .entry-meta {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.4;
  text-transform: none;
  color: var(--knd-page-text-color-light);
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-person .entry-meta {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-person .entry-meta {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-person .entry-meta {
    font-size: 14px;
  }
}

.tpl-person .entry-meta a[href*="tel"] {
  color: var(--knd-page-text-color);
  cursor: default;
}

.tpl-person .entry-meta a[href*="tel"]:hover {
  color: var(--knd-page-text-color);
}

.tpl-person .entry-data {
  padding: 24px;
}

.people-cards-shortcode .tpl-person:hover {
  background: var(--knd-page-bg-color);
}

.people-cards-shortcode .tpl-person .entry-preview:after {
  display: none;
}

.people-cards-shortcode .tpl-person .entry-data {
  padding-bottom: 0;
}

/** Partners **/
.tpl-org {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  margin-bottom: 48px;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .tpl-org {
    padding-bottom: 60%;
  }
}

.tpl-org .logo-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tpl-org .logo-link span {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  line-height: 130px;
}

.tpl-org img {
  max-width: 100%;
  max-height: 100%;
  vertical-aling: middle;
  width: auto;
  height: auto;
}

/* Search card */
.tpl-search {
  margin-bottom: 48px;
}

.tpl-search a {
  color: var(--knd-page-text-color);
}

.tpl-search .entry-title {
  font-size: 1.2em;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.44;
  font-weight: 600;
  padding-top: 0px;
  hyphens: auto;
  color: var(--knd-page-text-color);
  margin-bottom: 0;
}

.tpl-search .entry-meta {
  font-size: .85em;
  color: var(--knd-color-third);
  margin-top: 2px;
  margin-bottom: 1em;
}

.tpl-search .entry-summary {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .tpl-search .entry-summary {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tpl-search .entry-summary {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tpl-search .entry-summary {
    font-size: 19px;
  }
}

/*  Archive layouts */
.cards-holder.archive-post-list {
  padding-top: 0px;
}

.cards-holder.archive-post-list.next-page {
  padding-top: 48px;
}

.listing-bg {
  background: #eeeeee;
}

.featured-post {
  margin-top: 70px;
  margin-bottom: -70px;
}

.featured-post .cards-loop {
  transform: translateY(-70px);
}

.paging {
  font-size: 21px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.10em;
  line-height: 1.33;
  padding: 17.33333px 0 83.2px 0;
  text-align: center;
}

@media (min-width: 440px) and (max-width: 768px) {
  .paging {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .paging {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .paging {
    font-size: 25px;
  }
}

.paging .page-numbers {
  display: inline-block;
  color: var(--knd-page-text-color);
  padding: 0px 10px;
  transition: all 0.3;
}

.paging .page-numbers:hover, .paging .page-numbers:focus, .paging .page-numbers:active {
  color: var(--knd-page-bg-color);
  background: var(--knd-color-main);
}

.paging .page-numbers.current {
  color: var(--knd-page-bg-color);
  background: var(--knd-color-main);
}

.paging:empty {
  display: none;
}

/** == Single posts (_single.scss) == */
.entry-header-single {
  padding: 32px 0 0 0;
}

.entry-header-single .flex-cell {
  flex: 0 0 auto;
}

.entry-header-single .entry-title {
  font-size: 38px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1;
  letter-spacing: .03em;
  font-weight: 800;
  hyphens: auto;
}

@media (min-width: 440px) and (max-width: 768px) {
  .entry-header-single .entry-title {
    font-size: calc( 38px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .entry-header-single .entry-title {
    font-size: calc( 41px + 3 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .entry-header-single .entry-title {
    font-size: 44px;
  }
}

.entry-header-single .entry-meta {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--knd-color-main-dark);
  display: inline-block;
  padding: 4px 8px;
  padding: 4px 0px 13px 0px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .entry-header-single .entry-meta {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .entry-header-single .entry-meta {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .entry-header-single .entry-meta {
    font-size: 14px;
  }
}

body.plot-public-campaign .entry-header-single .entry-meta span.category {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 600;
}

@media (min-width: 440px) and (max-width: 768px) {
  body.plot-public-campaign .entry-header-single .entry-meta span.category {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body.plot-public-campaign .entry-header-single .entry-meta span.category {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  body.plot-public-campaign .entry-header-single .entry-meta span.category {
    font-size: 14px;
  }
}

body.plot-fundraising-org .entry-header-single .entry-meta span.category {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 600;
  color: var(--knd-color-third);
}

@media (min-width: 440px) and (max-width: 768px) {
  body.plot-fundraising-org .entry-header-single .entry-meta span.category {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body.plot-fundraising-org .entry-header-single .entry-meta span.category {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  body.plot-fundraising-org .entry-header-single .entry-meta span.category {
    font-size: 14px;
  }
}

.entry-header-single .entry-meta a {
  color: inherit;
}

.entry-header-single .entry-meta a:hover {
  text-decoration: underline;
}

.entry-header-single .entry-meta .date {
  color: var(--knd-page-text-color);
}

body.plot-public-campaign .entry-header-single .entry-meta .date {
  color: var(--knd-text1-color);
}

body.plot-public-campaign.single .entry-header-single .entry-meta .date {
  color: var(--knd-page-text-color);
}

.entry-header-single .entry-meta .sep {
  display: inline-block;
  width: 5px;
  height: 5px;
  border: solid 1px var(--knd-page-bg-color-dark);
  border-radius: 5px;
  padding: 0px;
  vertical-align: baseline;
  margin: 1px 7px 2px 7px;
}

body.plot-public-campaign .entry-header-single .entry-meta .sep {
  border-color: var(--knd-text1-color);
}

body.plot-public-campaign.single .entry-header-single .entry-meta .sep {
  border-color: var(--knd-page-text-color);
}

.entry-header-single .mobile-sharing {
  margin-top: 20px;
}

@media screen and (min-width: 800px) {
  .entry-preview-single {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.entry-preview-single .flex-cell {
  flex: 0 0 auto;
}

.introimg-figure {
  position: relative;
}

.introimg-figure .introimg {
  width: 100%;
  height: 0;
  padding-bottom: 65%;
  position: relative;
}

.introimg-figure .tpl-pictured-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.introimg-figure .wp-caption-text {
  padding-top: 4px;
  color: var(--knd-page-text-color-light);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
}

@media (min-width: 440px) and (max-width: 768px) {
  .introimg-figure .wp-caption-text {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .introimg-figure .wp-caption-text {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .introimg-figure .wp-caption-text {
    font-size: 14px;
  }
}

.introimg-figure .wp-caption-text .svg-icon {
  fill: var(--knd-page-text-color-light);
  width: 15px;
  height: 19px;
  margin-right: 8px;
  vertical-align: bottom;
}

.entry-content-single .entry-lead {
  font-size: 21px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 36.09023px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .entry-content-single .entry-lead {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .entry-content-single .entry-lead {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .entry-content-single .entry-lead {
    font-size: 25px;
  }
}

.single .entry-content {
  min-height: 20vh;
}

.knd-signle-after-content {
  margin-top: 24px;
}

.single-sharing-col {
  position: relative;
}

.regular-sharing {
  position: absolute;
  width: 45px;
  height: calc(100% - 20px);
  top: 0px;
  left: 0;
  z-index: 100;
}

.mobile-sharing .social-likes {
  padding: 8px 0;
}

.mobile-sharing .social-likes::after {
  clear: both;
  content: "";
  display: table;
}

.mobile-sharing .social-likes__widget {
  float: left;
  margin: 0 8px 0 0;
}

.tags-line {
  padding-top: 21.81818px;
}

.tags-line a {
  color: var(--knd-color-main-dark);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.33;
  font-weight: 600;
  margin-right: 4px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .tags-line a {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tags-line a {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .tags-line a {
    font-size: 14px;
  }
}

/** == Pages (_pages.scss) == **/
/** Parts **/
.page-header {
  text-align: center;
  padding: 48px 0;
}

.page-title {
  font-size: 51px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.05;
  font-weight: 600;
  letter-spacing: .001em;
  text-transform: uppercase;
}

@media (min-width: 440px) and (max-width: 768px) {
  .page-title {
    font-size: calc( 51px + 4 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-title {
    font-size: calc( 55px + 4 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .page-title {
    font-size: 59px;
  }
}

.page-intro {
  margin-top: 24px;
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
}

@media (min-width: 440px) and (max-width: 768px) {
  .page-intro {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-intro {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .page-intro {
    font-size: 33px;
  }
}

.page-content {
  padding-bottom: 48px;
  min-height: 60vh;
}

.page-crumb {
  text-align: center;
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.page-crumb a {
  color: var(--knd-color-main);
}

.page-crumb a:hover, .page-crumb a:focus, .page-crumb a:active {
  color: var(--knd-color-main-dark);
}

.knd-image-section {
  margin: 48px 0;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 480px) {
  .knd-image-section {
    margin: 57.6px 0;
  }
}

.knd-image-section:first-child {
  margin-top: 0;
}

.knd-img-bg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-color: var(--knd-page-text-color);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.knd-section-extend {
  margin-left: -11%;
  margin-right: -11%;
}

@media screen and (min-width: 400px) {
  .knd-section-extend {
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (min-width: 800px) {
  .knd-section-extend {
    margin-left: calc(400px - 50vw);
    margin-right: calc(400px - 50vw);
  }
}

@media screen and (min-width: 1104px) {
  .knd-section-extend {
    margin-left: -26.6%;
    margin-right: -26.6%;
  }
}

@media screen and (min-width: 800px) {
  .knd-section-extend-on-large {
    margin-left: calc(400px - 50vw);
    margin-right: calc(400px - 50vw);
  }
}

@media screen and (min-width: 1104px) {
  .knd-section-extend-on-large {
    margin-left: -26.6%;
    margin-right: -26.6%;
  }
}

.mark-title {
  font-size: 21px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.2;
  letter-spacing: .03em;
  font-weight: 800;
  margin-bottom: 26px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .mark-title {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mark-title {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .mark-title {
    font-size: 25px;
  }
}

.mark-text {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
}

@media (min-width: 440px) and (max-width: 768px) {
  .mark-text {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mark-text {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .mark-text {
    font-size: 19px;
  }
}

.mark-over .knd-section-extend {
  z-index: 1;
  position: relative;
}

.mark-over .knd-img-bg {
  z-index: 1;
  min-height: 320px;
}

.mark-over .knd-img-bg:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.18+27,0.31+51,0.65+100 */
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.1) 27%, rgba(0, 0, 0, 0.31) 51%, rgba(0, 0, 0, 0.7) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.1) 27%, rgba(0, 0, 0, 0.31) 51%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 27%, rgba(0, 0, 0, 0.31) 51%, rgba(0, 0, 0, 0.7) 100%);
}

.mark-over .kng-img-mark {
  z-index: 10;
  padding-bottom: 24px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 480px) {
  .mark-over .kng-img-mark {
    padding-bottom: 48px;
  }
}

.mark-over .mark-title {
  color: #ffffff;
}

.mark-over .mark-text {
  color: #ffffff;
}

.mark-under .kng-img-mark {
  margin-top: 26px;
}

.mark-under .mark-title {
  color: var(--knd-page-text-color);
}

.colored .knd-img-bg:after {
  display: none;
}

.colored .kng-img-mark {
  background: var(--knd-color-main);
  padding: 48px;
}

.colored .mark-title, .colored .mark-text {
  color: #ffffff;
}

.colored.mark-over .kng-img-mark {
  top: auto;
  transform: none;
  bottom: 0;
}

/* key phrase */
.knd-key-phrase {
  text-align: center;
  margin: 48px 0;
}

@media screen and (min-width: 480px) {
  .knd-key-phrase {
    margin: 57.6px 0;
  }
}

.knd-key-phrase h5 {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.knd-key-phrase h3 {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  margin: 0;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-key-phrase h3 {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-key-phrase h3 {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-key-phrase h3 {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .knd-key-phrase h3 {
    font-size: 36px;
  }
}

/* intext CTA */
.knd-intext-cta {
  margin: 48px 0;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .knd-intext-cta {
    margin: 57.6px 0;
  }
}

.knd-intext-cta .knd-section-extend {
  background: var(--knd-color-second);
  padding: 48px;
}

@media screen and (min-width: 800px) {
  .knd-intext-cta .knd-section-extend {
    padding: 57.6px 96px;
  }
}

.knd-intext-cta h5 {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.knd-intext-cta h3 {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  margin: 0 0 48px 0;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-intext-cta h3 {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-intext-cta h3 {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-intext-cta h3 {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .knd-intext-cta h3 {
    font-size: 36px;
  }
}

.knd-intext-cta .cta-button a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  padding: 16px 50px;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-intext-cta .cta-button a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-intext-cta .cta-button a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-intext-cta .cta-button a {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .knd-intext-cta .cta-button a {
    padding: 12px 35px;
  }
}

.knd-intext-cta .cta-button a:visited {
  color: #ffffff;
}

.knd-intext-cta .cta-button a:hover, .knd-intext-cta .cta-button a:focus, .knd-intext-cta .cta-button a:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-intext-cta .cta-button a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-intext-cta .cta-button a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-intext-cta .cta-button a {
    font-size: 19px;
  }
}

.knd-links {
  margin: 26px 0 39px;
}

.knd-links.center {
  text-align: center;
}

.knd-links.right {
  text-align: right;
}

.knd-links a {
  display: inline-block;
  font-weight: bold;
  margin-right: 1em;
}

.err-404-text {
  text-align: center;
  margin-bottom: 2em;
}

.err404-content {
  min-height: 70vh;
}

/** Donations **/
.leyka-campaign {
  margin-bottom: 48px;
}

.leyka-campaign .entry-header-single {
  text-align: center;
  margin: 0 auto 48px;
}

.leyka-campaign .campaign-card {
  margin-bottom: 48px;
}

.leyka-campaign div[id*="leyka-pf-"].card-2col {
  margin-bottom: 48px;
}

@media screen and (min-width: 1104px) {
  .leyka-campaign div[id*="leyka-pf-"].card-2col {
    margin-left: -96px;
    margin-right: -96px;
  }
}

.leyka-campaign .related-campaigns {
  text-align: center;
  margin-top: 28.8px;
  margin-bottom: 48px;
}

.leyka-campaign .related-campaigns a {
  display: inline-block;
  margin: 0 10px;
  color: var(--knd-color-main-dark);
}

.leyka-campaign .related-campaigns a:hover {
  text-decoration: underline;
}

.lk-thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 61.7%;
}

.lk-thumbnail a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .lk-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@supports not (display: flex) {
  .lk-thumbnail img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.leyka-scale-label .flex-cell {
  margin-bottom: 24px;
}

div[id*="leyka-pf-bottom"] .bottom-form__button button,
div[id*="leyka-pf-"] .inpage-card__action button {
  border-radius: 0;
  max-width: 170px;
}

.leyka-js .leyka-pf-bottom,
.leyka-pf__module {
  border-radius: 3px;
}

div[id*="leyka-pf-"] .inpage-card__thumb {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.lk-info p.closed {
  font-style: italic;
}

.single-leyka_campaign .donation_history {
  margin: 48px 0;
}

.donation_history .all-link {
  border-bottom: 1px solid var(--knd-page-bg-color-dark);
  padding: 13px 0;
}

.donation_history .all-link a {
  color: var(--knd-color-main);
}

.ldl-item {
  border-bottom: 1px solid var(--knd-page-bg-color-dark);
  padding: 13px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 440px) and (max-width: 768px) {
  .ldl-item {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ldl-item {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .ldl-item {
    font-size: 14px;
  }
}

.ldl-item:first-of-type {
  border-top: 1px solid var(--knd-page-bg-color-dark);
}

.ldl-item .amount {
  color: var(--knd-page-text-color);
  flex: 1 0 auto;
  width: 25%;
  min-width: 80px;
}

.ldl-item .meta {
  flex: 1 1 auto;
  width: 75%;
  padding-left: 6px;
  color: var(--knd-page-text-color-light);
}

.ldl-item .purpose {
  flex: 1 1 auto;
  width: 40%;
  padding-left: 6px;
  color: var(--knd-page-text-color-light);
}

.ldl-item .purpose a {
  color: var(--knd-color-main);
}

.ldl-item .purpose + .meta {
  width: 35%;
}

.post-type-archive-leyka_donation .heading {
  margin-bottom: 72px;
}

.post-type-archive-leyka_donation .heading a {
  color: var(--knd-color-main);
}

.post-type-archive-leyka_donation .heading a:hover {
  color: var(--knd-color-main-dark);
}

.post-type-archive-leyka_donation .paging {
  margin-top: 48px;
}

.post-type-archive-leyka_donation .main-content {
  margin-bottom: 48px;
}

/** == Built-in WordPress widgets (_builin.scss) == */
/* General large styles */
.widget-full .widget-full-title {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  padding-bottom: 33.6px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full .widget-full-title {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full .widget-full-title {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full .widget-full-title {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .widget-full .widget-full-title {
    font-size: 36px;
  }
}

.widget-full.widget_knd_team, .widget-full.widget_nav_menu, .widget-full.widget_search, .widget-full.widget_media_image, .widget-full.widget_media_audio, .widget-full.widget_media_video, .widget-full.widget_text {
  text-align: center;
  padding: 24px 0 48px;
  position: relative;
  width: calc(100% - 54px);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 400px) {
  .widget-full.widget_knd_team, .widget-full.widget_nav_menu, .widget-full.widget_search, .widget-full.widget_media_image, .widget-full.widget_media_audio, .widget-full.widget_media_video, .widget-full.widget_text {
    width: calc(100% - 80px);
  }
}

@media screen and (min-width: 1104px) {
  .widget-full.widget_knd_team, .widget-full.widget_nav_menu, .widget-full.widget_search, .widget-full.widget_media_image, .widget-full.widget_media_audio, .widget-full.widget_media_video, .widget-full.widget_text {
    width: 92%;
  }
}

.widget-bottom .widget-title {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 1.5em;
  text-transform: uppercase;
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-bottom .widget-title {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-bottom .widget-title {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-bottom .widget-title {
    font-size: 14px;
  }
}

.widget-full.widget_nav_menu li {
  display: inline-block;
  padding: 8px;
}

.widget-full.widget_nav_menu a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.33;
  letter-spacing: .03em;
  text-transform: uppercase;
  color: var(--knd-color-main);
  border-bottom: 1px solid;
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_nav_menu a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_nav_menu a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full.widget_nav_menu a {
    font-size: 19px;
  }
}

.widget-full.widget_nav_menu a:hover {
  color: var(--knd-color-main-dark);
}

.widget-bottom.widget_nav_menu li {
  margin-bottom: .5em;
}

.widget-bottom.widget_nav_menu a {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  text-transform: uppercase;
  color: var(--knd-color-main);
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-bottom.widget_nav_menu a {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-bottom.widget_nav_menu a {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-bottom.widget_nav_menu a {
    font-size: 14px;
  }
}

.widget-bottom.widget_nav_menu a:hover {
  color: var(--knd-color-main-dark);
}

.widget-full.widget_text {
  max-width: 1104px;
}

.widget-full.widget_text .textwidget {
  max-width: 720px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.widget-full.widget_text .textwidget a {
  color: var(--knd-color-main-dark);
  border-bottom: 1px solid var(--knd-color-main);
  transition: all .35s;
}

.widget-full.widget_text .textwidget a:hover, .widget-full.widget_text .textwidget a:focus, .widget-full.widget_text .textwidget a:active {
  color: var(--knd-color-main-dark);
  border-bottom-color: var(--knd-color-main-dark);
}

.widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3, .widget-full.widget_text .textwidget h4, .widget-full.widget_text .textwidget h5, .widget-full.widget_text .textwidget h6 {
  margin: 31.2px 0 16.9px;
  color: var(--knd-page-text-color);
}

.widget-full.widget_text .textwidget h2:first-child, .widget-full.widget_text .textwidget h3:first-child, .widget-full.widget_text .textwidget h4:first-child, .widget-full.widget_text .textwidget h5:first-child, .widget-full.widget_text .textwidget h6:first-child {
  margin-top: 0;
}

.widget-full.widget_text .textwidget h2:last-child, .widget-full.widget_text .textwidget h3:last-child, .widget-full.widget_text .textwidget h4:last-child, .widget-full.widget_text .textwidget h5:last-child, .widget-full.widget_text .textwidget h6:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget h2 strong, .widget-full.widget_text .textwidget h2 b, .widget-full.widget_text .textwidget h3 strong, .widget-full.widget_text .textwidget h3 b, .widget-full.widget_text .textwidget h4 strong, .widget-full.widget_text .textwidget h4 b, .widget-full.widget_text .textwidget h5 strong, .widget-full.widget_text .textwidget h5 b, .widget-full.widget_text .textwidget h6 strong, .widget-full.widget_text .textwidget h6 b {
  font-weight: normal;
}

.widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3 {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3 {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3 {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3 {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget h2, .widget-full.widget_text .textwidget h3 {
    font-size: 36px;
  }
}

.widget-full.widget_text .textwidget h4 {
  font-size: 1.2em;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.44;
  font-weight: 600;
  color: var(--knd-page-text-color);
}

.widget-full.widget_text .textwidget h5 {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
}

.widget-full.widget_text .textwidget h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

.widget-full.widget_text .textwidget strong, .widget-full.widget_text .textwidget b {
  color: var(--knd-page-text-color);
}

.widget-full.widget_text .textwidget em {
  font-style: italic;
  line-height: 1.5;
}

.widget-full.widget_text .textwidget ins {
  text-decoration: none;
  display: inline-block;
  background: #E7F3FF;
  padding: 0 8px;
}

.widget-full.widget_text .textwidget h2 + h3, .widget-full.widget_text .textwidget h2 + h4, .widget-full.widget_text .textwidget h2 + h5, .widget-full.widget_text .textwidget h2 + h6,
.widget-full.widget_text .textwidget h3 + h4, .widget-full.widget_text .textwidget h3 + h5, .widget-full.widget_text .textwidget h3 + h6,
.widget-full.widget_text .textwidget h4 + h5, .widget-full.widget_text .textwidget h4 + h6,
.widget-full.widget_text .textwidget h5 + h6 {
  margin-top: 13px;
}

.widget-full.widget_text .textwidget p, .widget-full.widget_text .textwidget iframe, .widget-full.widget_text .textwidget blockquote {
  margin-bottom: 26px;
}

.widget-full.widget_text .textwidget p:last-child, .widget-full.widget_text .textwidget iframe:last-child, .widget-full.widget_text .textwidget blockquote:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget figure {
  margin: 26px 0;
}

.widget-full.widget_text .textwidget figure:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget figure:first-child {
  margin-top: 0;
}

.widget-full.widget_text .textwidget ul {
  margin: 26px 0 26px 1.5em;
}

.widget-full.widget_text .textwidget ul li {
  position: relative;
  padding-left: 20px;
}

.widget-full.widget_text .textwidget ul li:before {
  content: '–';
  display: inline-block;
  line-height: inherit;
  position: absolute;
  left: 0;
  top: 0;
}

.widget-full.widget_text .textwidget ul li li {
  padding-left: 0;
  break-inside: avoid;
}

.widget-full.widget_text .textwidget ul li li:before {
  display: none;
}

.widget-full.widget_text .textwidget ul:first-child {
  margin-top: 0;
}

.widget-full.widget_text .textwidget ul ul {
  list-style: circle;
}

.widget-full.widget_text .textwidget ul ul ul {
  list-style: square;
}

.widget-full.widget_text .textwidget ol {
  list-style: decimal;
  margin: 26px 0 26px 1.5em;
}

.widget-full.widget_text .textwidget ol:first-child {
  margin-top: 0;
}

.widget-full.widget_text .textwidget ol ol {
  list-style: lower-alpha;
}

.widget-full.widget_text .textwidget ol ol ol {
  list-style: lower-roman;
}

.widget-full.widget_text .textwidget ul ul,
.widget-full.widget_text .textwidget ul ol,
.widget-full.widget_text .textwidget ol ul,
.widget-full.widget_text .textwidget ol ol {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget ul ul:first-child,
.widget-full.widget_text .textwidget ul ol:first-child,
.widget-full.widget_text .textwidget ol ul:first-child,
.widget-full.widget_text .textwidget ol ol:first-child {
  margin-top: 13px;
}

.widget-full.widget_text .textwidget li {
  margin-bottom: 13px;
}

.widget-full.widget_text .textwidget li:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget ul + p,
.widget-full.widget_text .textwidget ol + p {
  margin-top: 26px;
}

.widget-full.widget_text .textwidget blockquote {
  font-style: italic;
  position: relative;
  padding-left: 34px;
  margin-top: 52px;
}

.widget-full.widget_text .textwidget blockquote:before {
  content: '“';
  display: inline-block;
  font-size: 70px;
  line-height: 1;
  position: absolute;
  left: -12px;
  top: -12px;
  color: var(--knd-color-main);
}

.widget-full.widget_text .textwidget blockquote:first-child {
  margin-top: 0;
}

.widget-full.widget_text .textwidget blockquote:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget pre {
  margin-bottom: 26px;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, monospace;
  font-size: 0.8em;
  line-height: 1.75;
}

.widget-full.widget_text .textwidget pre:last-child {
  margin-bottom: 0;
}

.widget-full.widget_text .textwidget .wp-caption {
  max-width: 100%;
  margin-bottom: 26px;
}

.widget-full.widget_text .textwidget .wp-caption.aligncenter {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget .wp-caption.alignleft {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
  }
  .widget-full.widget_text .textwidget .wp-caption.alignright {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
  }
}

.widget-full.widget_text .textwidget .wp-caption img {
  max-width: 100%;
  height: auto;
}

.widget-full.widget_text .textwidget .video-caption {
  color: var(--knd-page-text-color-light);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: -8px;
  margin-bottom: 26px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_text .textwidget .video-caption {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_text .textwidget .video-caption {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full.widget_text .textwidget .video-caption {
    font-size: 14px;
  }
}

.widget-full.widget_text .textwidget .video-caption .svg-icon {
  fill: var(--knd-page-text-color-light);
  width: 15px;
  height: 19px;
  margin-right: 8px;
  vertical-align: bottom;
}

.widget-full.widget_text .textwidget .wp-caption-text {
  color: var(--knd-page-text-color-light);
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 12px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_text .textwidget .wp-caption-text {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_text .textwidget .wp-caption-text {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full.widget_text .textwidget .wp-caption-text {
    font-size: 14px;
  }
}

.widget-full.widget_text .textwidget .wp-caption-text .svg-icon {
  fill: var(--knd-page-text-color-light);
  width: 15px;
  height: 19px;
  margin-right: 8px;
  vertical-align: bottom;
}

.widget-full.widget_text .textwidget p + video-caption {
  margin-top: 0;
}

.widget-full.widget_text .textwidget a[href].alignleft {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget a[href].alignleft {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
  }
}

.widget-full.widget_text .textwidget a[href].alignleft img {
  max-width: 100%;
  height: auto;
}

.widget-full.widget_text .textwidget a[href].alignright {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget a[href].alignright {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
  }
}

.widget-full.widget_text .textwidget a[href].alignright img {
  max-width: 100%;
  height: auto;
}

.widget-full.widget_text .textwidget img.alignleft,
.widget-full.widget_text .textwidget img[align="left"] {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget img.alignleft,
  .widget-full.widget_text .textwidget img[align="left"] {
    float: left;
    margin: 0 48px 10px 0;
    max-width: 50%;
    height: auto;
  }
}

.widget-full.widget_text .textwidget img.alignright,
.widget-full.widget_text .textwidget img[align="right"] {
  display: block;
  margin-bottom: 26px;
}

@media screen and (min-width: 480px) {
  .widget-full.widget_text .textwidget img.alignright,
  .widget-full.widget_text .textwidget img[align="right"] {
    float: right;
    margin: 0 0 10px 48px;
    max-width: 50%;
    height: auto;
  }
}

.widget-full.widget_text .textwidget pre, .widget-full.widget_text .textwidget code {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_text .textwidget pre, .widget-full.widget_text .textwidget code {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_text .textwidget pre, .widget-full.widget_text .textwidget code {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .widget-full.widget_text .textwidget pre, .widget-full.widget_text .textwidget code {
    font-size: 19px;
  }
}

.widget-full.widget_text.widget_custom_html .textwidget {
  max-width: none;
}

.widget-full.widget_media_image {
  text-align: center;
}

.widget-full.widget_media_image .heading + img {
  max-width: 1104px;
  height: auto;
}

.widget-full.widget_media_video,
.widget-full.widget_media_audio {
  max-width: 720px;
}

.widget-full.widget_search {
  max-width: 720px;
  position: relative;
  z-index: 1;
}

.widget-full.widget_search .searchform {
  position: relative;
}

.widget-full.widget_search .icon-search {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
  fill: var(--knd-color-main);
  z-index: 5;
}

.widget-full.widget_search .search-field {
  position: relative;
  z-index: 10;
  background: transparent;
  border: 2px solid var(--knd-color-main);
  padding-left: 54px;
}

.widget-full.widget_search .search-field::placeholder {
  color: transparent;
}

.widget-full.widget_search .search-field:focus, .widget-full.widget_search .search-field:active {
  border-color: var(--knd-color-main-dark);
  background: #ffffff;
  padding-left: 24px;
}

.widget-full.widget_search .search-field:focus::placeholder, .widget-full.widget_search .search-field:active::placeholder {
  color: var(--knd-page-text-color);
}

@media screen and (min-width: 800px) {
  .widget-full.widget_search .search-field {
    padding-right: 90px;
  }
}

@media screen and (min-width: 800px) {
  .widget-full.widget_search .action {
    display: inline-block;
    position: absolute;
    right: 15px;
    font-size: 12px;
    font-family: "SourceSansPro", Arial, sans-serif;
    line-height: 1.33;
    font-weight: 600;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: normal;
    color: var(--knd-page-text-color-light);
    top: 20px;
  }
}

@media screen and (min-width: 800px) and (min-width: 440px) and (max-width: 768px) {
  .widget-full.widget_search .action {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media screen and (min-width: 800px) and (min-width: 768px) and (max-width: 1024px) {
  .widget-full.widget_search .action {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media screen and (min-width: 800px) and (min-width: 1024px) {
  .widget-full.widget_search .action {
    font-size: 14px;
  }
}

.widget-full.widget_search .sr-num {
  font-size: .85em;
  color: var(--knd-page-text-color-light);
  text-align: left;
  margin-top: 8px;
}

.widget-full.widget_search.search-holder {
  padding-bottom: 0;
}

.widget-bottom.widget_search .searchform {
  position: relative;
}

.widget-bottom.widget_search .icon-search {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 14px;
  left: 10px;
  fill: var(--knd-page-text-color);
}

.widget-bottom.widget_search .search-field {
  border: 1px solid var(--knd-page-text-color);
  background: transparent;
  padding: 12px 12px 12px 44px;
}

.widget-bottom.widget_search .search-field:focus, .widget-bottom.widget_search .search-field:active {
  background: #ffffff;
}

.widget-bottom.widget_search .action {
  display: none;
}

.leyka_donations_list .widget-full-title {
  text-align: center;
}

.leyka_donations_list.widget-full {
  padding-top: 24px;
  padding-bottom: 48px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

/** == Kandinsky-specific widgets (_builin.scss) == */
.button {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .button {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .button {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .button {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .button {
    padding: 12px 35px;
  }
}

.button:visited {
  color: #ffffff;
}

.button:hover, .button:focus, .button:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}

.tpl-pictured-bg {
  width: 100%;
  height: 100%;
  background-color: var(--knd-page-bg-color-dark);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.knd-homepage-sidebar, .knd-archive-sidebar {
  padding-top: 48px;
  position: relative;
}

@media screen and (min-width: 800px) {
  .knd-homepage-sidebar, .knd-archive-sidebar {
    padding-top: 86.4px;
  }
}

body.home.plot-public-campaign .knd-homepage-sidebar, body.home.plot-public-campaign .knd-archive-sidebar {
  background: var(--knd-color-main);
  color: var(--knd-text1-color);
}

.knd-archive-sidebar {
  position: relative;
  background: #eeeeee;
  padding-top: 57.6px;
}

.the-content .knd-recommend,
.knd-recommend {
  font-family: Arial, serif !important;
  font-style: italic;
  font-weight: normal !important;
  letter-spacing: normal !important;
  font-size: 14px;
  line-height: 1.45;
  color: #000;
  padding: 13px 19.5px;
  background-color: #f7f7af;
  margin: 26px 0;
  text-align: left !important;
}

.knd-news-widget, .knd-shortlist-widget, .knd-partners-widget, .knd-donations-widget {
  padding-top: 54px;
  background: #eeeeee;
}

body.plot-public-campaign .knd-news-widget, body.plot-public-campaign .knd-shortlist-widget, body.plot-public-campaign .knd-partners-widget, body.plot-public-campaign .knd-donations-widget {
  background: var(--knd-color-third);
}

.knd-news-widget h2.section-title, .knd-shortlist-widget h2.section-title, .knd-partners-widget h2.section-title, .knd-donations-widget h2.section-title {
  font-size: 21px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 600;
  color: var(--knd-page-text-color);
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-news-widget h2.section-title, .knd-shortlist-widget h2.section-title, .knd-partners-widget h2.section-title, .knd-donations-widget h2.section-title {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-news-widget h2.section-title, .knd-shortlist-widget h2.section-title, .knd-partners-widget h2.section-title, .knd-donations-widget h2.section-title {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-news-widget h2.section-title, .knd-shortlist-widget h2.section-title, .knd-partners-widget h2.section-title, .knd-donations-widget h2.section-title {
    font-size: 25px;
  }
}

.knd-news-widget .cards-row, .knd-shortlist-widget .cards-row, .knd-partners-widget .cards-row, .knd-donations-widget .cards-row {
  padding-top: 48px;
}

.knd-shortlist-widget {
  background: var(--knd-color-third);
}

body.plot-public-campaign .knd-shortlist-widget {
  background: var(--knd-color-third);
}

.knd-shortlist-widget h2.section-title {
  color: var(--knd-text1-color);
}

@media screen and (min-width: 800px) {
  .knd-shortlist-widget h2.section-title {
    width: 50%;
  }
}

@media screen and (min-width: 800px) {
  .knd-shortlist-widget .knd-widget-head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 13px;
    border-bottom: 1px solid #ffffff;
  }
}

.knd-shortlist-widget .section-links {
  padding-top: 8px;
}

@media screen and (min-width: 800px) {
  .knd-shortlist-widget .section-links {
    text-align: right;
    width: 50%;
    padding-top: 0;
  }
}

.knd-shortlist-widget .section-links a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.33;
  letter-spacing: .03em;
  color: var(--knd-color-main-dark);
  margin-right: 20px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-shortlist-widget .section-links a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-shortlist-widget .section-links a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-shortlist-widget .section-links a {
    font-size: 19px;
  }
}

@media screen and (min-width: 800px) {
  .knd-shortlist-widget .section-links a {
    margin-left: 44px;
    margin-right: 0px;
  }
}

.knd-shortlist-widget .section-links a:hover, .knd-shortlist-widget .section-links a:focus, .knd-shortlist-widget .section-links a:active {
  color: var(--knd-color-main);
}

body.plot-fundraising-org .knd-shortlist-widget .section-links a {
  color: var(--knd-text1-color);
}

.knd-ourorg-widget, .knd-joinus-widget {
  display: none;
  text-align: center;
  padding-bottom: 74.4px;
}

@media screen and (min-width: 800px) {
  .knd-ourorg-widget, .knd-joinus-widget {
    display: block;
  }
}

.knd-ourorg-widget h2, .knd-joinus-widget h2 {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-transform: uppercase;
}

body.plot-public-campaign .knd-ourorg-widget h2, body.plot-public-campaign .knd-joinus-widget h2 {
  color: var(--knd-text2-color);
}

body.plot-fundraising-org .knd-ourorg-widget h2, body.plot-fundraising-org .knd-joinus-widget h2 {
  color: var(--knd-color-third);
}

.knd-ourorg-widget .knd-whoweare-headlike-text-wrapper, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper {
  padding-top: 18.04511px;
}

.knd-ourorg-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  hyphens: none;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-ourorg-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-ourorg-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-ourorg-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .knd-ourorg-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text, .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
    font-size: 36px;
  }
}

.knd-whoweare-section {
  padding-bottom: 0px;
}

@media screen and (min-width: 800px) {
  .knd-whoweare-section {
    padding-bottom: 96px;
  }
}

.knd-whoweare-section .whoweare-item {
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 57.6px;
}

@media screen and (max-width: 799px) {
  .knd-whoweare-section .whoweare-item {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 800px) {
  .knd-whoweare-section .whoweare-item {
    text-align: left;
    min-height: none;
  }
}

.knd-whoweare-section .whoweare-item h3 {
  font-size: 21px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.2;
  letter-spacing: .03em;
  font-weight: 800;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-whoweare-section .whoweare-item h3 {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-whoweare-section .whoweare-item h3 {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-whoweare-section .whoweare-item h3 {
    font-size: 25px;
  }
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item h3 {
  color: var(--knd-color-third);
}

.knd-whoweare-section .whoweare-item p {
  padding-top: 15px;
  min-height: 76px;
}

@media screen and (min-width: 800px) {
  .knd-whoweare-section .whoweare-item p {
    min-height: 200px;
  }
}

@media screen and (min-width: 1104px) {
  .knd-whoweare-section .whoweare-item p {
    min-height: 146px;
  }
}

.knd-whoweare-section .whoweare-item a {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 600;
  display: inline-block;
  color: var(--knd-page-text-color);
  border-bottom: 4px solid var(--knd-color-main);
  padding: 0px 0px 4px 0px;
  text-decoration: none;
  position: absolute;
  bottom: 0px;
  left: 1px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-whoweare-section .whoweare-item a {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-whoweare-section .whoweare-item a {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-whoweare-section .whoweare-item a {
    font-size: 14px;
  }
}

.knd-whoweare-section .whoweare-item a:hover {
  text-decoration: none;
  border-color: var(--knd-color-main-dark);
}

body.plot-public-campaign .knd-whoweare-section .whoweare-item a {
  color: var(--knd-text2-color);
  border-color: var(--knd-text2-color);
}

body.plot-public-campaign .knd-whoweare-section .whoweare-item a:hover {
  color: var(--knd-text2-color-dark);
  border-color: var(--knd-text2-color-dark);
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item a {
  color: var(--knd-color-third);
  border-color: var(--knd-color-third);
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item a:hover {
  color: var(--knd-color-third-dark);
  border-color: var(--knd-color-third-dark);
}

body.plot-public-campaign .knd-whoweare-section .whoweare-item:last-of-type h3 {
  color: var(--knd-text3-color);
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item:last-of-type h3 {
  color: var(--knd-color-main);
}

.knd-whoweare-section .whoweare-item:last-of-type a {
  border-color: var(--knd-color-second);
}

.knd-whoweare-section .whoweare-item:last-of-type a:hover {
  border-color: var(--knd-color-second-dark);
}

body.plot-public-campaign .knd-whoweare-section .whoweare-item:last-of-type a {
  color: var(--knd-text3-color);
  border-color: var(--knd-text3-color);
}

body.plot-public-campaign .knd-whoweare-section .whoweare-item:last-of-type a:hover {
  color: var(--knd-text3-color-dark);
  border-color: var(--knd-text3-color-dark);
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item:last-of-type a {
  color: var(--knd-color-main);
  border-color: var(--knd-color-main);
}

body.plot-fundraising-org .knd-whoweare-section .whoweare-item:last-of-type a:hover {
  color: var(--knd-color-main-dark);
  border-color: var(--knd-color-main-dark);
}

.knd-joinus-widget {
  display: block;
  background: var(--knd-color-second);
  padding-top: 58.56px;
  padding-bottom: 64.8px;
  color: var(--knd-page-text-color);
}

.knd-joinus-widget h2 {
  letter-spacing: 0.1em;
}

body.plot-public-campaign .knd-joinus-widget h2 {
  color: var(--knd-text1-color);
}

body.plot-fundraising-org .knd-joinus-widget h2 {
  color: var(--knd-text1-color);
}

.knd-joinus-widget .knd-whoweare-headlike-text-wrapper {
  justify-content: center;
}

.knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
  line-height: 1.33;
  hyphens: none;
}

body.plot-public-campaign .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
  color: var(--knd-text1-color);
}

body.plot-fundraising-org .knd-joinus-widget .knd-whoweare-headlike-text-wrapper .knd-whoweare-headlike-text {
  color: var(--knd-text1-color);
}

.knd-joinus-widget .knd-cta-wrapper-wide {
  padding-top: 24px;
}

.knd-joinus-widget .knd-cta-wrapper-wide a.cta {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
  font-size: 21px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  padding: 16px 50px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    padding: 12px 35px;
  }
}

.knd-joinus-widget .knd-cta-wrapper-wide a.cta:visited {
  color: #ffffff;
}

.knd-joinus-widget .knd-cta-wrapper-wide a.cta:hover, .knd-joinus-widget .knd-cta-wrapper-wide a.cta:focus, .knd-joinus-widget .knd-cta-wrapper-wide a.cta:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-joinus-widget .knd-cta-wrapper-wide a.cta {
    font-size: 25px;
  }
}

.knd-partners-widget {
  padding-top: 56px;
  background: var(--knd-page-bg-color);
}

body.plot-public-campaign .knd-partners-widget {
  background: #ffffff;
}

body.plot-public-campaign .knd-partners-widget h2.section-title {
  color: var(--knd-color-third);
}

.knd-partners-widget h2.section-title {
  color: var(--knd-page-text-color);
}

.knd-partners-widget .knd-news-widget-body {
  padding-top: 33px;
}

.knd-partners-widget .tpl-org img {
  max-width: 180px;
}

@media screen and (min-width: 800px) {
  .knd-partners-widget .tpl-org img {
    max-width: 100%;
  }
}

.widget-full.widget_knd_team {
  max-width: 1104px;
  padding-bottom: 0;
}

.knd-people-title {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  color: var(--knd-page-text-color);
  margin-bottom: 26px;
  text-align: center;
}

@media (min-width: 440px) and (max-width: 768px) {
  .knd-people-title {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .knd-people-title {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .knd-people-title {
    font-size: 33px;
  }
}

@media screen and (min-width: 480px) {
  .knd-people-title {
    font-size: 36px;
  }
}

.knd-people-gallery {
  margin-left: -24px;
  margin-right: -24px;
}

.knd-people-gallery .tpl-person {
  margin-bottom: 0;
}

.knd-people-gallery .tpl-person a {
  display: block;
  color: var(--knd-page-text-color);
}

.knd-people-gallery .tpl-person .entry-data {
  text-align: left;
  padding: 24px 0 0;
}

.knd-donations-widget {
  background: var(--knd-page-bg-color);
  padding-top: 33.6px;
  padding-bottom: 14.4px;
}

.knd-donations-widget .widget-full-title {
  text-align: center;
}

.leyka-loop .tpl-post, .leyka-loop .tpl-related-post {
  border: 1px solid var(--knd-page-bg-color-dark);
  padding: 24px 25.92px 24px;
}

@media screen and (min-width: 480px) {
  .leyka-loop .tpl-post, .leyka-loop .tpl-related-post {
    padding: 51.84px 48px 24px;
  }
}

@media screen and (min-width: 800px) {
  .leyka-loop .tpl-post, .leyka-loop .tpl-related-post {
    padding: 24px 25.92px 24px;
  }
}

@media screen and (min-width: 1104px) {
  .leyka-loop .tpl-post, .leyka-loop .tpl-related-post {
    padding: 51.84px 48px 24px;
  }
}

.leyka-loop .tpl-post .help-purpose, .leyka-loop .tpl-related-post .help-purpose {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .03em;
  font-weight: 400;
  color: var(--knd-color-main-dark);
  padding-top: 24px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post .help-purpose, .leyka-loop .tpl-related-post .help-purpose {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post .help-purpose, .leyka-loop .tpl-related-post .help-purpose {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post .help-purpose, .leyka-loop .tpl-related-post .help-purpose {
    font-size: 14px;
  }
}

.leyka-loop .tpl-post .lk-title, .leyka-loop .tpl-related-post .lk-title {
  font-size: 21px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.2;
  letter-spacing: .03em;
  font-weight: 800;
  padding-top: 22.85714px;
  padding-bottom: 7px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post .lk-title, .leyka-loop .tpl-related-post .lk-title {
    font-size: calc( 21px + 2 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post .lk-title, .leyka-loop .tpl-related-post .lk-title {
    font-size: calc( 23px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post .lk-title, .leyka-loop .tpl-related-post .lk-title {
    font-size: 25px;
  }
}

.leyka-loop .tpl-post p, .leyka-loop .tpl-related-post p {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  line-height: 1.33;
  padding-bottom: 48px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post p, .leyka-loop .tpl-related-post p {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post p, .leyka-loop .tpl-related-post p {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post p, .leyka-loop .tpl-related-post p {
    font-size: 19px;
  }
}

.leyka-loop .tpl-post .leyka-scale-scale, .leyka-loop .tpl-related-post .leyka-scale-scale {
  width: 100%;
  height: 7px;
}

.leyka-loop .tpl-post .leyka-scale-scale .target, .leyka-loop .tpl-related-post .leyka-scale-scale .target {
  height: inherit;
  background: #eeeeee;
}

.leyka-loop .tpl-post .leyka-scale-scale .target .collected, .leyka-loop .tpl-related-post .leyka-scale-scale .target .collected {
  height: inherit;
  background: var(--knd-color-main);
}

.leyka-loop .tpl-post .leyka-scale-label, .leyka-loop .tpl-related-post .leyka-scale-label {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.33;
  font-weight: 400;
  color: var(--knd-page-text-color-light);
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post .leyka-scale-label, .leyka-loop .tpl-related-post .leyka-scale-label {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-label, .leyka-loop .tpl-related-post .leyka-scale-label {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-label, .leyka-loop .tpl-related-post .leyka-scale-label {
    font-size: 19px;
  }
}

.leyka-loop .tpl-post .leyka-scale-label .caption, .leyka-loop .tpl-related-post .leyka-scale-label .caption {
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .03em;
  font-weight: 400;
  padding-top: 12.63158px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post .leyka-scale-label .caption, .leyka-loop .tpl-related-post .leyka-scale-label .caption {
    font-size: calc( 12px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-label .caption, .leyka-loop .tpl-related-post .leyka-scale-label .caption {
    font-size: calc( 13px + 1 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-label .caption, .leyka-loop .tpl-related-post .leyka-scale-label .caption {
    font-size: 14px;
  }
}

.leyka-loop .tpl-post .leyka-scale-label .sum, .leyka-loop .tpl-related-post .leyka-scale-label .sum {
  color: var(--knd-color-main);
}

.leyka-loop .tpl-post .leyka-scale-label .knd-campaign-needed .sum, .leyka-loop .tpl-related-post .leyka-scale-label .knd-campaign-needed .sum {
  color: var(--knd-page-text-color);
}

.leyka-loop .tpl-post .leyka-scale-button-alone, .leyka-loop .tpl-related-post .leyka-scale-button-alone {
  text-align: right;
}

.leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 34.28571px;
  padding: 10px 30px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
    padding: 12px 35px;
  }
}

.leyka-loop .tpl-post .leyka-scale-button-alone a:visited, .leyka-loop .tpl-related-post .leyka-scale-button-alone a:visited {
  color: #ffffff;
}

.leyka-loop .tpl-post .leyka-scale-button-alone a:hover, .leyka-loop .tpl-related-post .leyka-scale-button-alone a:hover, .leyka-loop .tpl-post .leyka-scale-button-alone a:focus, .leyka-loop .tpl-related-post .leyka-scale-button-alone a:focus, .leyka-loop .tpl-post .leyka-scale-button-alone a:active, .leyka-loop .tpl-related-post .leyka-scale-button-alone a:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}

@media screen and (min-width: 480px) {
  .leyka-loop .tpl-post .leyka-scale-button-alone a, .leyka-loop .tpl-related-post .leyka-scale-button-alone a {
    padding: 12px 33px;
  }
}

/** == Hero image at home (_hero.scss) == */
.hero-section {
  background-color: var(--knd-page-bg-color-dark);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-section:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(top, transparent 10%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(top, transparent 10%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, transparent 10%, rgba(0, 0, 0, 0.8) 100%);
}

.hero-section .container {
  position: relative;
  z-index: 10;
}

.hero-section .hero-content {
  padding-bottom: 48px;
  height: calc(100vh - 77px);
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

@media screen and (min-width: 480px) {
  .hero-section .hero-content {
    padding-bottom: 10vh;
    width: 75%;
  }
}

@media screen and (min-width: 800px) {
  .hero-section .hero-content {
    width: 60%;
    padding-bottom: 10vh;
    height: calc(100vh - 101px);
  }
}

@media screen and (min-width: 1104px) {
  .hero-section .hero-content {
    width: 50%;
    padding-bottom: 15vh;
  }
}

@media screen and (min-width: 1104px) and (min-height: 950px) {
  .hero-section .hero-content {
    height: 580px;
    padding-bottom: 100px;
  }
}

.hero-title {
  font-size: 28px;
  font-family: "Exo2", Arial, sans-serif;
  line-height: 1.11;
  letter-spacing: .03em;
  font-weight: 800;
  color: #ffffff;
}

@media (min-width: 440px) and (max-width: 768px) {
  .hero-title {
    font-size: calc( 28px + 3 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero-title {
    font-size: calc( 31px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .hero-title {
    font-size: 33px;
  }
}

.hero-text {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  line-height: 1.33;
  color: #ffffff;
  margin-top: 24px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .hero-text {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero-text {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .hero-text {
    font-size: 19px;
  }
}

.hero-button {
  font-size: 16px;
  font-family: "SourceSansPro", Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .03em;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  background: var(--knd-color-main);
  border: 1px solid var(--knd-color-main);
  transition: all 0.3;
  text-align: center;
  padding: 6px 16px;
  margin-top: 48px;
}

@media (min-width: 440px) and (max-width: 768px) {
  .hero-button {
    font-size: calc( 16px + 1 * ( ( 100vw - 440px) / 328 ));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero-button {
    font-size: calc( 17px + 2 * ( ( 100vw - 768px) / 256 ));
  }
}

@media (min-width: 1024px) {
  .hero-button {
    font-size: 19px;
  }
}

@media screen and (min-width: 480px) {
  .hero-button {
    padding: 12px 35px;
  }
}

.hero-button:visited {
  color: #ffffff;
}

.hero-button:hover, .hero-button:focus, .hero-button:active {
  background: var(--knd-color-main-dark);
  color: #ffffff;
  border-color: var(--knd-color-main-dark);
}
