/** == Reset (_reset.scss) == **/
// Normalisations and reset styles

/*  Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
	content: '';
	content: none;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}


/*  Normalize.css */
html {  //  normalisation for font size and position on page
	font-size: 100%; 
	//overflow-y: scroll; 
	-webkit-text-size-adjust: 100%; 
	-ms-text-size-adjust: 100%;
}

// Focus states
a:focus,
a:hover,
a:active {
	outline: 0;
	outline: none;
}

// HTML5
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
iframe {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls]) {
	display: none;
}

// Always support hidden attr 
[hidden] {
	display: none; }

// Prevents sub and sup affecting line-height in all browsers
sub,
sup {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}

// Imgs
img {	
	border: 0; 
	-ms-interpolation-mode: bicubic;
	vertical-align: bottom;
	max-width: 100%;
	height: auto;
}

img[alt] {
	font-size: 10px;
	line-height: inherit;
	color: #666;
	font-family: inherit;
}

a img {
	border: 0;
	vertical-align: middle;
}

svg:not(:root) {
	overflow: hidden; }



/** Forms **/
button,
input,
select,
textarea {
	font-size: 100%; // Corrects font size not being inherited in all browsers 
	margin: 0; // Addresses margins set differently in IE6/7, F3/4, S5, Chrome 
	vertical-align: baseline; // Improves appearance and consistency in all browsers 
	*vertical-align: middle;
}

button,
input {
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
  *overflow: visible; // Inner spacing ie IE6/7
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; // Corrects inability to style clickable 'input' types in iOS 
	cursor: pointer;
}


input[type="checkbox"],
input[type="radio"] {
	padding: 0; // Addresses excess padding in IE8/9 
}

input[type="search"]::-webkit-search-decoration { // Corrects inner padding displayed oddly in S5, Chrome on OSX 
	-webkit-appearance: none;
}

input[type=search] {
   -moz-appearance:none;
   -webkit-appearance:none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;  
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }

button::-moz-focus-inner,
input::-moz-focus-inner { 
// Corrects inner padding and border displayed oddly in FF3/4 
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ 
	border: 0;
	padding: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {	
	outline: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px;
}

textarea {
	overflow: auto; // Removes default vertical scrollbar in IE6/7/8/9 
	padding-left: 3px;
	vertical-align: top; // Improves readability and alignment in all browsers 
	width: 100%;
}

