/** == Social (_social.scss) == */
// Social icons and sharing buttons


/** Common settings  **/
$social_color: (
	vk:        #517397,
	facebook:  #3B5998,
	twitter:   #1DA1F2,
	instagram: #B7308C,
	pinterest: #DE2127,
	youtube:   #E21D17,
	telegram:  #2CA5E0,
	whatsapp:  #69BD35,
	viber:     #774C98,
	ok:        #F58220
);

@function social_color($key) {
	@if map-has-key($social_color, $key) {
		@return map-get($social_color, $key);
	}

	@warn "Unknown '#{$key}' in $social_color.";
	@return null;
}

@mixin social-button-link($button-size, $icon-size) {
	display: inline-block;			
	text-align: center;
	vertical-align: middle;	
	transition: all 0.2s;
	text-decoration: none;
	width: $button-size;
	height: $button-size;
	padding: ($button-size - $icon-size)/2 0;
}


/** Social links **/
.knd-social-links {
	list-style-type: none;
	margin: 0;
	padding: 0;

	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-end;
	
	.links-right & {
		@include for(medium-screens) {
			justify-content: flex-end;
		}
	}

	li {
		text-align: center;
		
		a {
			@include social-button-link(46px, 22px);			
			span { display: none; }
		}

		.svg-icon {
			display: inline-block;
			vertical-align: top;
			width: 20px;
			height: 20px;
		}
	}
}


/**  Sharing **/
.social-likes {
	@include cf;	
}

.social-likes__widget  {			
	cursor: pointer;
		
	a {
		@include social-button-link(44px, 22px);
		padding: 9px 0;
	}
	
	.sh-text { display: none; }
	
	.svg-icon {
		display: inline-block;
		vertical-align: moddle;
		fill: #ffffff;					
		vertical-align: middle;
		width: 22px;
		height: 22px;
	}
}

@each $item in vk, facebook, twitter, telegram, whatsapp, viber, ok  {
		
	.social-likes__widget_#{$item} a {
		background: social_color($item);
		
		&:hover, &:focus, &:active {
			background: transparentize(call(social_color, $item), 0.2);
		}
		
		&:last-of-type {
			border: none;
		}		
	}
}
