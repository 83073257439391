/** == Footers (_footer.scss) == */
// Global footer styling


/** Bottom branding pannel with social links **/
.bottom-bar {    
    background: $color-gray-light;
    padding: $base*2.2 0 0;
    color: $color-black;
    
    body.plot-public-campaign & {
       color: $color-text-contrast;
       background: $color-third;
    }

    .knd-social-links  {
       
        li a {
            .svg-icon {
               fill: $color-black;
               
               body.plot-public-campaign & {
                   fill: $color-text-contrast;
               }
           } 
        }
    }

    h2.logo-name {
        padding-top: 8px;
    }
}

.bottom-branding {
    padding-bottom: $gut/2;
    .flex-cell { margin-bottom: $gut/2; }
}



/** Footer **/
.site-footer {
    
    @include knd-footer-text();
    padding: 10px 0px 30px 0px;
    background: $color-gray-light;
    color: $color-black;
    
    body.plot-public-campaign & {
        color: $color-text-contrast;
        background: $color-third;
    }
    
    a {
        color: $color-main-dark;

        &:hover, &:focus, &:active {
            text-decoration: underline;
            color: $color-main;
        }
        
        body.plot-fundraising-org & {
            color: $color-third;
            
            &:hover, &:focus, &:active {
                color: $color-third-dark;
            }
        }
        
    }
    
    p {
        hyphens: none;
        margin-bottom: $base/2;
        &:last-child { margin-bottom: 0; }
    }
    
    .copy {
        @include knd-supersmall-text();
        color: $color-black;
        max-width: 300px;
        opacity: .75;

        a { 
            white-space: nowrap;
            color: $color-black;
        }

    
        body.plot-public-campaign & {
            color: $color-text-contrast;
            a { color: $color-text-contrast; }

            opacity: .5;
        }
    }
    
    //layout
    .widget-area {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    
    .widget-bottom {
        flex: 0 0 auto;
        width: calc(100% - 24px);
        padding-bottom: $gut;
        
        @include for(small-screens){            
            width: calc(50% - 24px);
        }
        
        @include for(medium-screens){
            &:nth-of-type(2),
            &:nth-of-type(3) {              
                width: calc(25% - 24px);
            }           
        }
        
        @include for(large-screens){            
            width: calc(33.3% - 24px);
            
            &.widget_nav_menu {             
                width: calc(17% - 24px);            
            }
            
            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: calc(15% - 24px);
            }
            
            &:nth-of-type(2) {
                //padding-left:$gut;
            }
        }
    }
    
    .hr {
        margin: 0;
        color: transparent;
        display: block;
        border-bottom: 1px solid rgba(0,0,0, 0.2);
        
        body.plot-public-campaign & {
           border-bottom: 1px solid $color-second;
        }
        
        @include for(xxlarge-screens) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}



/** Footer credits block */
.footer-credits {
    padding-top: $gut/3;
}

.knd-brand {    
    position: relative;
    @include cf;


    .knd-icon {
        display: inline-block;
        width: 100%; 
        height: 100%;
    }
    
    a {
        float: right;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .knd-banner {
        display: block;
        flex: 0 0 auto;
        width: 48px;
        height: 48px; 
    }
        
    .support {
        @include knd-supersmall-text();
        line-height: 16px;
        color: $color-text;
        padding-right: 20px;
        width: 104px;
        text-align: right;
        display: none;

        @include for(small-screens) {
            display: block;
        }

        opacity: .75;

        body.plot-public-campaign & {
            color: $color-text-contrast;
            opacity: .5;
        }
    }
}


